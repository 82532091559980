.ai-chat-main .chat-main {
  padding-bottom: 50px;
}
.chat-main .left-chat {
}
.chat-main .right-toggling {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 5px;
}
.chat-main .right-toggling .with-ms-5px {
  margin-left: 5px;
}
.chat-main .chat-page-header{
  
}
.chat-main .right-toggling .badge-custom,
.chat-main .right-toggling .read-msg,
.chat-main .right-toggling .mute-msg, 
.chat-main .right-toggling .delivered-msg
{
  display: none;
}
.chat-main .left-chat.with-unread-count .right-toggling .badge-custom {
  display: inline-flex;
}
.chat-main .left-chat.with-delivered-msg .right-toggling .delivered-msg{
  display: block;
}
.chat-main .left-chat.with-read-msg .right-toggling .read-msg {
  display: block;
}
.chat-main .left-chat.with-mute-msg .right-toggling .mute-msg {
  display: block;
}
 .chat-date-seperator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
 .chat-date-seperator .seperator-line {
  width: 80%;
  border-top: 1px solid var(--color-white-05);
}
 .chat-date-seperator .date-text {
  padding: 0px 30px;
  letter-spacing: 2px;
}
.chat-wrapper .chat-box .chat-msg .chat-text-box.with-media .chat-img{
  height: 100px;
  width: 100px;
}
.chat-wrapper .chat-box.view-task .chat-msg .chat-text-box{
 background-color: var(--color-white-10);
}
.chat-wrapper .chat-box .edited_icon{
  display: none;
}
.chat-wrapper .chat-box.edited .edited_icon{
  display: block;
}
.chat-wrapper .chat-box.right-side-msg  .chat-action-option.edited_icon{
  left: -35px;
  right: auto;
}
.chat-wrapper .chat-box  .chat-action-option.edited_icon{
  left: auto;
  right: -37px;
}
.chat-text-box .chat-video .play-icon , .comman-image-box .play-icon{
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50% , -50%);

}
.chat-main-box .chat-wrapper .chat-box.width-custom{
  width: 600px;
  /* max-width: 38%; */
}
.task-modal-offcanvas-open .chat-main-box .chat-wrapper .chat-box.width-custom{
  width: 450px;
}
.chat-main-box .chat-wrapper .chat-box.w-52per{
  width: 52%;
  max-width: 52%;
}
.chat-audio{
  
}
.chat-audio audio::-webkit-media-controls-panel {
  background-color: var( --base-modal-header-color);
  background-color:var(--color-white-10-solid);
 
  border:5px solid var( --base-modal-header-color) !important;
}

audio::-webkit-media-controls-current-time-display , audio::-webkit-media-controls-time-remaining-display , audio::-webkit-media-controls-timeline , audio::-webkit-media-controls-play-button , audio::-webkit-media-controls-volume-slider , audio::-webkit-media-controls-volume-slider-container , audio::-webkit-media-controls-seek-back-button , audio::-webkit-media-controls-seek-forward-button , audio::-webkit-media-controls-fullscreen-button, audio::-webkit-media-controls-rewind-button, audio::-webkit-media-controls-return-to-realtime-button, audio::-webkit-media-controls-toggle-closed-captions-button, audio::-webkit-media-controls-mute-button{
  filter: invert(1);
}
.react-audio-player {
  background-color: var( --base-modal-header-color);
  border-radius: 3px;
}
.rhap_volume-controls , .rhap_additional-controls{
  display: none !important;
}
.chat-audio .audio_player{
  background-color: var(--color-white-03);
  border-radius:3px;
  padding: 10px;
}
.chat-audio .rhap_time{
color: var(--white);
}
.chat-audio.withf12 .rhap_time{
  font-size: 12px;
}
.chat-audio .rhap_progress-container{
  flex-grow: 1;
  background: var(--color-white-05);
  border-radius: 40px;
  height: 5px;
}
 /* .chat-audio .rhap_progress-bar{
  background: transparent;
} */
.chat-audio .rhap_progress-bar-show-download{
  background-color: var(--color-white-05);
}
.chat-audio .rhap_progress-filled{
  background-color: var(--color-green);
}
.chat-audio .rhap_progress-indicator{
  width: 15px;
  height: 15px;
  top: -6px;
  background: var(--color-green);
}
.chat-audio.small .rhap_progress-indicator{
  height: 10px;
  width: 10px;
  top: -3px;

}
.chat-audio.small .rhap_progress-bar{
  height: 4px;
}
.muteoption.with_small_modal{
  right: -18px;
  top: -15px;
}
.chat-audio .rhap_progress-section{
  width: 100%;
}
.chat-audio .rhap_main-controls-button{
color: var(--color-white-20);
margin: 0;
}
.chat-audio .rhap_download-progress{
  background-color: var(--color-white-10);
}
.replay-button{
  left: -10px!important;
}
.chat-main-box .chat-wrapper .chat-box.w-60per.replay-selected,
.chat-main-box .chat-wrapper .chat-box.replay-selected{
  border-left: 2px solid var(--inprogress-status);
  max-width: 100%;
  background-color: var(--color-white-03);
  width: 100%;
}
.chat-main-box .chat-wrapper .chat-box.w-60per.task-active-border .chat-text-box,
.chat-main-box .chat-wrapper .chat-box.task-active-border .chat-text-box{
  border:1px solid var(--color-green-blue-20);
}
.chat-main-box .chat-wrapper .chat-box.w-60per .chat-text-box,
.chat-main-box .chat-wrapper .chat-box .chat-text-box{
  border:1px solid transparent;
}
.chat-wrapper .chat-box.right-side-msg.replay-selected{
  border-right: 2px solid var(--inprogress-status);
  border-left: 0!important;
}
.reply-caption{
  position: relative;
}
.reply-caption .replay-button{
  display: block!important;
}
.chat-wrapper .reply-caption.chat-box.right-side-msg .chat-msg {
  text-align: left!important;
}
.reply-caption .reply-box{
  display: block!important;
}
.chat-text-box .absolute-task-card{
  position: absolute;
  left: -480px;
  top: 0;
  bottom: auto;
  width: 450px;
}
.chat-msg .chat-text-box.with-task-card{
  width: 400px;
  border-bottom: 2px solid var(--task-list-card-boeder);
  background-color: var(--color-white-03);
  padding: 5px 10px;
}
.pt2px{
  padding-top: 2px;
}
.chat-wrapper .chat-box .chat-msg .chat-text-box.with-task-card{
  background-color: var(--color-white-03);
}
.EmojiPickerReact{
  background: var(--base-body-color);
  z-index: 2;
}
.EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label , .EmojiPickerReact .epr-search-container input.epr-search{
  background: var(--base-body-color);

}
.EmojiPickerReact .epr-search-container input.epr-search{
  border-color: var(--color-white-05);
}
aside.EmojiPickerReact.epr-main{
  border-color: var(--color-white-05);
}
aside.EmojiPickerReact.epr-main:has(input:placeholder-shown) .epr-category-nav{
  border-bottom: 1px solid var(--color-white-05);
}




/* Feed chat modal */
.Feedchatmain{
  padding: 50px 0px;
}
.FeedchatContain .Commanwave{
  border-radius: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--color-white-03);
  transform: translate(-50%, -50%);
}
.FeedchatContain .wave1{
  /* height: 120%;
  width: 120%; */
  height: 115%;
  width: 115%;
}
.FeedchatContain .wave2{
  /* height: 140%;
    width: 140%; */
    height: 137%;
    width: 137%;
}
.FeedchatContain .wave3{
  /* height:160%;
  width: 160%; */
  height: 158%;
  width: 158%;
}
.Feedchat-backdrop , .Feedchat-backdrop.show{
  background-color: var(--bs-backdrop-bg);
  opacity: 1;
}
.CallerImg.withMargin120px{
  margin: 0px 120px;
}
.scroller {
  text-align: left;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  scroll-snap-type: y mandatory;
  scroll-behavior: auto;
}
.scroller .box1 {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
}
.scroller .box2 {
  overflow: auto;
  height:100%;
  width:100%;
}
.chat-wrapper .chat-box.right-side-msg .rightsidecontentend{
justify-content: end;
}
