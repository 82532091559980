/*task accordian view*/
.task-list-accordian {}

.table-listing-accordian .accordion-item {
  max-height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
}

/*List View Toggle css*/
.list-view-toggle {
  /* width: calc(50% - 20px); */
  width: 100%;
  transition: var(--transiton-3s);
}
.list-view-toggle .comman_card_view .task-left-part-toggle{
  flex-wrap: wrap;
} 
.list-view-toggle .comman_card_view .category-wrapper .total-text{
  display: none;
}
.list-view-toggle .comman_card_view.task-list-card .task-list-name{
  width: 180px;
  font-size: 12px;
}
.list-view-toggle  .task-list-card.list-view.comman_card_view .task-mid-part{
  padding: 3px 0px;
}
.modal-open .list-view-toggle {
  padding: 0 0;
  margin-top: 10px;
  /* padding-right: 10px; */
}

.list-view-toggle .task-list-card {
  margin-bottom: 20px;
  border: 1px solid transparent;
  /* border: 1px solid var(--color-border); */
}

.list-view-toggle .task-list-card.active {
  opacity: 1;
  border: var(--color-green-50) 1px solid;
  border-bottom: var(--color-green-50) 1px solid!important;
  /* overflow: hidden!important; */
}
.taskcardliteversion{
  border-top:1px solid transparent;
  border-left:1px solid transparent;
  border-right:1px solid transparent;
}
.taskcardliteversion.active{
  border: var(--color-green-50) 1px solid !important;
}
.taskcardliteversion.active{
  border-bottom: 1px solid transparent;
}
.list-view-toggle .list-view .w-215 {
  width: 100%;
}

.list-view-toggle .list-view .w-260 {
  width: auto;
}

.list-view-toggle .list-view .w-460 {
  width: auto;
}

.list-view-toggle .list-view .w-120 {
  width: auto;
}

.list-view-toggle .list-view .w_172 {}

.list-view-toggle .list-view .w-140 {
  width: auto;
}
.list-view-toggle .list-toggle-view-none{
  /* display:none; */
}
.list-view-toggle .list-view.active .w-200 {
  width: 168px;
}

.list-view-toggle .list-view .w-185 {
  width: 100%;
  /* display:none; */
}

.list-view-toggle .list-view .main-calc-width {
  width: 100%;
  min-width: calc(100% - 600px);
}

.list-view-toggle .list-view .main-calc-width-task {
  min-width: calc(100% - 430px);
  width: 100%;
  /* width: auto; */
}
.list-view-toggle .list-view.comman_card_view .main-calc-width-task{
  width: auto;
}
.list-view-toggle .list-view .width100minus160 {
  width: 100%;
}

.pill-bottom-top {
  display: none;
}

.list-view-toggle .pill-bottom-top {
  display: block;
}

.list-view-toggle .task-list-card.list-view .task-left {
  width: calc(100% - 160px);
  display: flex;
}
.task-list-card .progress_wrapper{
  display: flex;
  flex-grow: 1;
}
.task-list-card-parent.active .task-list-card{
  border: var(--color-green-50) 1px solid;
}
.list-view-toggle .task-list-card.list-view .task-assign-section {}

/* .list-view-toggle .list-view .task-list-wrapper>.align-items-start {
  align-items: start !important;
} */

.list-view-toggle .task-list-card.list-view .task-list-detail {
  width: calc(100% - 0px);
  padding-left: 0px;
  /* align-items: start; */
}
/* .list-view-toggle .task-list-card.list-view.comman_card_view .task-list-detail{
  width: 100%;
} */
.white-border-box.white-box-padding-5px {
  padding: 5px;
}

.list-view-toggle .task-list-card.list-view .task-bottom-part {
  padding-top: 10px;
  width: 100%;
}

.list-view-toggle .task-list-card.list-view .task-bottom-part {}

.list-view-toggle .task-list-card.list-view .task-mid-part {
  padding: 0px 0px;
  padding-bottom: 0;
  border-bottom: none;
}

.list-view-toggle.custom-accordian-main .task-list-accordian-main {
  margin-top: 0px;
}

.task-list-card .comman_active.with_separator_10 {
  display: none !important;
}

.list-view-toggle .task-list-card .comman_active.with_separator_10 {
  display: block !important;
}

.task-list-card .pill-task-active {
  display: block !important;
}

.list-view-toggle .task-list-card .pill-task-active {
  display: none !important;
}

.task-list-card .pill-task-default {
  display: none !important;
}

.list-view-toggle .task-list-card .pill-task-default {
  display: block !important;
}

.list-view-toggle .task-list-card .custom-datepicker {
  font-size: 12px;
  /* width: 90px; */
  width: auto;
}
.list-view-toggle .list-toggle-view-box.white-border-box{
  background-color: var(--color-white-05);
  border: 0;
}
.list-view-toggle .task-list-card .custom-datepicker .react-datepicker-wrapper{
  display: none;
}
.list-view-toggle .task-list-card .img-width-18 {
  width: 16px;
}

.list-view-toggle .task-list-card .c-dots.c-12 {
  width: 10px;
  height: 10px;
}

.list-view-toggle .task-list-card .task-mid-part.for-content-between {
  justify-content: space-between;
}

.task-list-card .task-mid-part.for-content-between {
  justify-content: flex-end;
}

.priority-checkbox .check_box .check_span_img {
  height: 15px;
  width: 15px;
  background-size: contain;
}
.priority-checkbox .check_box .check_span_img.h30w30{
  height: 30px;
  width: 30px;
}
.list-view .w-215 {
  width: 215px;
}

.list-view .w-260 {
  width: 260px;
}

.list-view .w-460 {
  width: auto;
}

.list-view .w-120 {
  width: 90px;
}

.list-view .w_172 {
  width: 120px;
}

.list-view .w-140 {
  width: 140px;
}

.list-view .w-185 {
  width: 110px;
}

.list-view .main-calc-width {
  width: auto;
}

.list-view .width100minus160 {
  width: calc(100% - 160px);
}

.list-view .width100minus25 {
  width: calc(100% - 25px) !important;
}

.list-view-toggle .list-view .category-wrapper .action_icon .total-text {
  display: none;
}

.task-list-card {
  background: var(--color-white-03);
  border-radius: 3px;
  /* padding: 7px 0px; */
  padding-right: 0px;
  /* margin-bottom: 15px; */
  display: inline-block;
  /* border-bottom: 1px solid transparent; */
  /* border-bottom: 2px solid var(--task-list-card-border); */
  margin-bottom: 2px;
  position: relative;
  /* transition: var(--transiton-3s); */
}
#list_view .task-list-card {
  border-radius: 0px;;
}
.task-list-card:last-child{
  /* border-bottom: 2px solid transparent; */
}

.benchmark-accordian-scroll .benchmark-accordian-box:last-child .task-list-card {
  margin-bottom: 0px;
}

.task-list-card:hover {
  background: var(--color-white-05);
}

.task-list-card.no-hover:hover {
  background: var(--color-white-03);
}

.task-list-card.bg-white-03 {
  background: var(--color-white-03);
}
.comman_action_icon .action_icon.nohover:hover{
  background-color: var(--color-white-03-solid) !important;
}
.task-list-card.p-15 {
  padding: 15px;
}

.task-list-card.p-10-15 {
  padding: 10px 15px;
}

.task-list-card .task-pin-status {
  position: absolute;
  top: -5px;
  right: 0px;
}

.task-list-card.list-view {
  width: 100%;
  /* margin-bottom: 5px; */
  /* margin-bottom: 10px; */
  margin-bottom: 2px;
  /* border-bottom: 2px solid var(--task-list-card-border); */
}
.taskCountToggle div:last-child .task-list-card.list-view{
  border-bottom: 0;
}

.task-list-card .task-left {
  width: calc(100% - 160px);
}

.task-list-card.list-view .task-left {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.task-list-card .task-assign-section {
  margin-left: -80px;
}

.list-view .task-list-wrapper>.align-items-start {
  align-items: center !important;
}

.task-list-card.list-view .task-assign-section {
  margin: 0;
}

.task-list-card.list-view .task-list-detail {
  display: flex;
  align-items: center;
}

.task-list-card.list-view .task-mid-part {
  padding: 0px;
  border-bottom: transparent 0px solid;
}

.task-list-card .task-assign-section .pill-min-width {
  min-width: 58px;
}

.list-view-toggle .task-list-card.list-view .number-counter-toggle {
  display: none !important;
}

.list-view-toggle .task-list-card.list-view .table-list-attechment .comman_action_icon span {}

.task-list-card.list-view .task-bottom-part {
  padding-top: 0px;
}

.task-list-card .task-list-detail {
  width: calc(100% - 0px);
  /* padding-left: 10px; */
  /* padding-right:5px; */
}

.task-list-card .task-list-name {
  /* padding: 10px 0px; */
  font-family: var(--title-family);
  font-weight: 400;
  width: calc(100% - 40px);
  line-height: 1.2;
  font-size:var(--space-13);
  /* padding: 16px 0px; */
  padding-left: 10px;
  font-size: 14px;
}

.task-list-card .task-list-name .task-list-name-link {
  display: block;
}

.list-view-toggle .task-list-card .task-list-name {
  padding-right: 0px;
  width: 250px;
}

.task-list-card .task-mid-part {
  padding: 10px 0px;
  border-bottom: var(--color-border) 1px solid;
}

.task-list-card .task-drag-icon {
  padding: 3px 7px;
}

.task-list-card .task-drag-icon img {
  max-width: inherit;
}

.task-list-card .task-bottom-part {
  padding-top: 10px;
}

.task-list-card .table-list-progress {
  height: 25px;
}

.task-list-card .table-list-attechment {
  height: 25px;
}

.task-list-card .c_date_img_text {
  font-size: 13px;
}

.table-list-attechment.w_172 .dropdown {
  min-width: 60px;
  border: 0;
}

.table-list-attechment.w_172 .dropdown,
.table-list-attechment.w_172 .dropend.w_172 {
  min-width: 60px;
  border: 0;
}

.task-list-card.list-view .task-mid-part .custom-datepicker .react-datepicker-popper {
  width: 340px;
}

.mr-10 {
  margin-right: 10px;
}

.task-list-accordian .pill-wrapper .filter-list-view {
  display: none;
}

.task-list-accordian.list-view-toggle .pill-wrapper .filter-list-view {}

.task-list-accordian.list-view-toggle .pill-wrapper {
  top: 1px;
  margin-left: 0;
  margin-right: 0;
}

.custom-accordian-main .task-list-accordian-main {
  display: flex;
  flex-direction: column;
  margin-top: -25px;
}

.task-list-accordian .pill-wrapper {
  position: sticky;
  top: 4px;
  margin-left: auto;
  z-index: 8;
  /* margin-right: 30px; */
}
.task-list-accordian .pill-wrapper.top_8px{
  top:8px
}
/* .task-list-accordian .pill-wrapper.with-right-pos {
  right: 40px;
} */

.task-list-accordian .pill-wrapper.with-top-30px {
  top: 30px;
}

.task-list-accordian.list-view-toggle .pill-wrapper.with-top-30px {
  top: 0px;
}

.task-list-accordian.list-view-toggle .pill-wrapper .filter-lists-menu {
  position: absolute;
  right: 0px;
  top: 4px;
  max-width: 64%;
}

.task-list-accordian.list-view-toggle .pill-wrapper .filter-lists-menu .badge {
  width: 56%;
  padding: 5px;
  margin-left: 8px;
  /* border-bottom: 1px solid transparent; */
  font-size: 10px;
}

.task-list-accordian.list-view-toggle .pill-wrapper .filter-list-toggle {
  display: none;
}

.task-list-accordian.list-view-toggle .pill-wrapper .filter-list-toggle.block {
  display: block;
}

.task-list-accordian.list-view-toggle .pill-wrapper .filter-lists-menu .badge div {
  justify-content: space-between;
}

.task-list-card .white-border-box {
  min-height: 25px;
  min-width: 25px;
  height: 25px;
  padding: 0;
}
.task-list-card .white-border-box.h32w32 {
  height: 32px;
  width: 32px;
}

.task-list-card .white-border-box.h32wauto {
  height: 32px;
}

.comman-main-box .accordian-header-box .filter-lists-menu.min-width-190 {
  min-width: 190px;
}

.task-list-card.list-view.document-list-card.list-view .w_172 {
  width: 105px;
}

.task-list-card.list-view.document-list-card.list-view .w-120 {
  width: 110px;
}

.task-list-card.list-view.document-list-card.list-view .w-52 {
  width: 58px;
}

.task-list-card.list-view.document-list-card .min-width-auto {
  min-width: auto;
}

.list-view-toggle .task-list-card.document-list-card .task-top-part a.title-fonts {
  width: 60%;
}

.list-view-toggle .task-list-card.view-benchmark-list-card .task-top-part a.title-fonts {
  width: auto;
}

.list-view-toggle .task-list-card.list-view.document-list-card .w-120 {
  width: auto;
}

.list-view-toggle .task-list-card.list-view.document-list-card .with_separator_10.card-view-less-padding {
  padding: 0px 8px;
}

.list-view-toggle .task-list-card.list-view.document-list-card .with_separator_10.card-view-less-padding::before {
  display: block;
}

.list-view-toggle .task-list-card.list-view.document-list-card .with_separator_10.card-view-less-padding.before-dnone:before {
  display: none;
}

.list-view-toggle .task-list-card.list-view.document-list-card .table-list-attechment .comman_action_icon span {
  padding-left: 5px;
}

.list-view-toggle .task-list-card.list-view.document-list-card .w_172 {
  width: 85px;
}

.task-list-card.document-list-card .task-assign-section .pill-min-width {
  min-width: 28px;
}

.document-list-card .category-wrapper.with-min-width {
  min-width: 100px;
}

.document-list-card .category-wrapper.with-min-width125 {
  min-width: 125px;
}

.task-list-accordian .document-list-card .category-wrapper.company-name-width {
  max-width: 78px;
}

.task-list-accordian.list-view-toggle .document-list-card .category-wrapper.company-name-width {
  min-width: 70px;
  max-width: 70px;
}

/* Scan 3d customize css */
.dropdown-menu {
  position: absolute;
}

.hotspot {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: none;
  box-sizing: border-box;
  border-radius: 50%;
  opacity: 0;
}

.hotspot.equipmentBg{
  width: 25px;
  height: 25px;
}

.poi_anchor {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: none;
  box-sizing: border-box;
  border-radius: 50%;
  opacity: 0;
}

.zeroTask {
  /* background: radial-gradient(#f2f2f2 5%, #e2e2e2 60%); */
  background: radial-gradient(#f3f3f3 5%, #bababa 60%);
  opacity: 1;
}

.colorTask {
  background: radial-gradient(#00ff6a 5%, #00fdd5 60%);
  opacity: 1;
}

.colorRequest {
  background: radial-gradient(#FFCF87 5%, #FF9900 60%);
  opacity: 1;
}

.colorEquipment {
  /* background: radial-gradient(#11ABF1 5%, #1150F1 60%); */
  background: radial-gradient(#f1eebc 5%, #dbd135 60%);
  opacity: 1;
  width: 25px;
  height: 25px;
}

.colorDone {
  background:  radial-gradient(#acffd9 8%, var(--color-green) 60%);
  opacity: 1;
}
.colorInprogress {
  background:  radial-gradient(#9fe6f7 5%, var(--color-blue) 60%);
  opacity: 1;
}

.colorPois {
  background: radial-gradient(#7c7a7a 5%, #121212 60%);
  opacity: 1;
}

.colorDirection {
  /* background: red; */
  opacity: 1;
}

.colorMask {
  background: radial-gradient(#11ABF1 5%, #1150F1 60%);
  opacity: 1;
  width: 20px;
  height: 20px;
}

.hotspot:not([data-visible]) {
  /* opacity: 0.2; */
}

.view-button {
  background: #fff;
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-size: 11px;
  font-weight: 600;
  max-width: 128px;
  overflow-wrap: break-word;
  padding: 3px;
  position: absolute;
  width: max-content;
  height: max-content;
  transform: translate3d(-50%, -50%, 0);
  bottom: 10px;
}

.hotspotadd {
  height: 50px;
  padding: 10px;
  font-size: 16px;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 99;
}

model-viewer {
  width: 100%;
  height: 100%;
}

.list-view-toggle .task-list-card.list-view .card-view-none {
  display: none;
}

.list-view-toggle .task-list-card.list-view .assignee-list-wrapper {}

.list-view-toggle .task-list-card.list-view .with_separator_10.card-view-less-padding:before {
  display: none;
}

.list-view-toggle .task-list-card.list-view .table-list-attechment .comman_action_icon span {
  padding-left: 0px;
}

.list-view-toggle .task-list-card.list-view .white-border-box {
  min-width: auto;
  margin-right: 3px;
}

.list-view-toggle .task-list-card.list-view {}

.list-view-toggle .list-view .multi_inner_wrapper.with-min-width {
  min-width: 30px;
  /* margin-right: 5px; */
  /* margin-right: 10px; */
  justify-content: flex-end;
}

.list-view-toggle .task-list-card.list-view .with_separator_10.card-view-less-padding {
  /* padding: 4px; */
  /* padding: 3px; */
}

.list-view-toggle .list-view .w-185 {
  width: 70px;
}

.list-view-toggle .task-list-card .table-list-progress {
  height: 25px;
}

.list-view-toggle .progress-slider .progress.w_100 {
  width: 30px;
}

.list-view-toggle .list-view .w_172 {
  width: 60px;
}

.list-view-toggle .list-view .progress-slider .progress-text {
  font-size: 10px;
  width: 30px;
}

.list-view-toggle .list-view .table-list-attechment.w_172 .dropdown {
  min-width: 20px;
  border: 0;
}

.list-view-toggle .list-view .custom-datepicker.w-90.datepicker-input .react-datepicker-wrapper .react-datepicker__input-container input {
  font-size: 11px;
}

.menu-nine-dots-img:hover .bottom-left-checklist {
  display: block;
}

.list-view-toggle .task-right-static-options .display-none-card-view {
  display: none !important;
}

.list-view-toggle .document-list-card .category-wrapper.with-min-width {
  min-width: auto;
  max-width: 70px;
}

.list-view-toggle .document-list-card .category-wrapper.with-min-width125 {
  min-width: auto;
  max-width: 90px;
}

.task-name-input .form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
}

/* .task-list-card .left-premium-title{
  flex-grow: 1;
    max-width: calc(100% - 290px);
} */


/*Kan ban view Css*/
.kanban-view.list-view-toggle {
  width: 100%;
}

.kanban-view.task-list-accordian .accordion-item {
  min-width: 380px;
  padding: 5px;
  padding-top: 0;
  margin-top: 5px;
  width: 33.33%;
  overflow: hidden auto;
  /* padding-bottom: var(--scroll-bottom-padding); */
  padding-bottom: 115px;
}

.kanban-view.custom-accordian-main .task-list-accordian-main {
  flex-direction: row;
  position: relative;
  margin-top: -5px;
  height: 100%;
}

.kanban-view.task-list-accordian .pill-wrapper {
  /* width: 250px;
  right: 0px;
  order: 12;
  margin-left: -250px;
  z-index: 8;
  height: 25px; */
  position: fixed;
  width: 250px;
  right: 20px;
  order: 12;
  margin-left: -250px;
  z-index: 8;
  height: 25px;
  top: 144px;
}
.kanban-view .task-list-card.list-view{
  padding: 15px 10px;
  padding-bottom: 0;
}
.kanban-view .task-list-card .task-list-name{
  padding: 5px 0px;
  padding-left: 10px;
}
.kanban-view .task-list-card .task-bottom-part-full{
  width: 100%;
  padding-right: 0px;
  /* padding-top: 10px; */
}

.kanban-view .task-list-card .task-bottom-part-full .task-right-static-options{
  width: 100%;
  padding-top: 10px;
}
.kanban-view .kanbanbottomspacing{
  margin-bottom: 10px;
}
/* .kanban-view .task-list-card .task-bottom-part-full .task-right-static-options>div{
  flex-wrap: wrap;
  row-gap: 8px;
} */
.kanban-view .list-view .main-calc-width-task{
  width: calc(100% - 40px);
  flex-grow: 0 !important;
}
.kanban-view .list-view .w-185{
  flex-grow: 1;
  width: 70px;
}
.kanban-view .task-list-card.list-view .task-left{
  flex-wrap: wrap;
}

.kanban-view .list-view .task-bottom-part-full .table-list-progress div {
  width: 100%;
}

.kanban-view .list-view .task-bottom-part-full .table-list-progress>div:first-child {
  display: none;
}

.kanban-view .list-view .progress-wrapper-link{
  width: 100%;
}

.kanban-view .list-view .task-bottom-part-full .table-list-progress div.progress-text {
  width: auto;
  padding-left: 10px;
}

.kanban-view .task-list-card.list-view .task-left-part-toggle{
  width: calc(100% - 50px) !important;
}

.kanban-view .task-list-card.list-view .task-list-detail{
  align-items: self-start;
  flex-direction: column;
}

.kanban-view .task-list-card.list-view .task-assign-section {
  margin-left: -50px;
}

.kanban-view .list-view .main-calc-width .task-list-name {
  width: auto;
}

.kanban-view .list-view .main-calc-width {
  width: auto;
  min-width: calc(100% - 220px);
}

.kanban-view .task-list-card .task-list-name {
  width: 100%;
}

.kanban-view .list-view .main-calc-width {
  width: auto;
  min-width: calc(100% - 220px);
}

.kanban-view .list-view .w_172{
  width: auto;
}
.kanban-view .task-list-card.list-view .company-name-width {
  display: none;
}
.kanban-view .table-list-attechment.w_172 .dropdown, .table-list-attechment.w_172 .dropend.w_172{
  min-width:auto ;
}
/* .kanban-view .with_separator_10{
  padding: 0px 3px;
} */
.kanban-view .with_separator_10:before{
  display:none;
}
.kanban-view .task-list-card.list-view.schedule-card-main .task-assign-section {
  margin-left: -15px;
}
.kanban-view  .absoluteDotsTask{
  position: absolute;
    top: 10px;
    right: 10px;
}
.kanban-view .comman_action_icon{
  margin-bottom: 10px;
}
.task-list-card.taskcardliteversion{
  /* margin: 0; */
  /* margin-bottom: 20px; */
}
.task-list-card{
  padding: 20px;
}
.task-list-card.taskcardliteversion .task-list-name{
  /* padding: 0 0 0 10px; */
  padding: 0;
}
.Litecardwrapper{
  margin-bottom: 20px;
  /* padding: 20px 0px 0px 10px; */
  width: 100%;
}
.task-modal-offcanvas-open .Litecardwrapper{
  padding: 0px 0px 0px 0px;
}
.taskcardliteversion .with_separator_10:before{
  display: none;
}
.taskcardliteversion  .location.with_separator_10{
  padding-left:0;
  padding-right: 5px;
}
.taskcardliteversion  .location.with_separator_10:before{
  display: block !important;
}
.taskcardliteversion .task-right-static-options .with_separator_10{
  padding-left: 0px;
}
.liteversion-date-box{
  position: absolute;
  left: -80px;
  top: 0;
  transform: translate(3px, 20%);
}
.border-bottom.border-last-none:last-child{
  border-bottom: 0px!important;
}