.view-benchmark-wrapper {}
.benchmark-summary {}
.benchmark-summary .summary-top-task {}
.benchmark-summary .summary-top-task .total-task {
  padding-right: 15px;
}
.benchmark-summary .summary-top-task .task-type-wrapper .task-type {
  padding: 0px 15px;
}
.benchmark-summary .week-schedule-main {
  padding: 15px 0px;
}
.benchmark-summary .offer-list-main-scroll {
  height: calc(100% - 70px);
}
.benchmark-summary .invite-input {
  padding-top: 15px;
}
.list-wrapper-top .max-w-120px {
  max-width: 120px;
}
/* benchmark listing */
.benchmark-box {
  margin-bottom: 15px;
  background-color: var(--base-header-color);
  font-size: 12px;
  border-radius: 3px;
}
.benchmark-box .benchmark-box-header {
  padding: 5px 10px;
  overflow: visible;
}
.benchmark-box .benchmark-box-detail .benchmark-day-box {
  width: 20%;
}
/* Like unlike  */
.benchmark-accordian-box .like-btn img {
  display: none;
}
.benchmark-accordian-box .like-btn.unlike .unlike-icon {
  display: block;
}
.benchmark-accordian-box .like-btn.like .like-icon {
  display: block;
}
.benchmark-task-accordian {
  height: 100%;
  overflow: hidden auto;
}
.benchmark-task-accordian .benchmark-accordian-box {
  margin-bottom: 15px;
}
.benchmark-task-accordian .benchmark-accordian-box:last-child {
  margin-bottom: 0px;
}
.benchmark-listing-main .benchmark-provider-list .provider-detail-top .absolute-check {
  position: absolute;
  top: -5px;
  right: 0px;
}
/* week schedule box */
.week-schedule-box {
  background: var(--color-white-03);
  border-radius: 3px;
  padding: 10px 5px;
}
.week-schedule-box .week-box {
  width: 14.28%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.week-schedule-box .week-box .available-status {
  padding: 10px 0px;
}
.week-schedule-box .week-box .available-status img {
  display: none;
}
.week-schedule-box .week-box .available-status.available .available-slot {
  display: block;
}
.week-schedule-box .week-box .available-status.not-available .not-available-slot {
  display: block;
}
.week-schedule-box .week-box .available-status.available .available-slot{
  display: block;
}
.week-schedule-box .week-box .available-status.available .not-available-slot{
  display: none;
}
.week-schedule-box .week-box .available-status.not-available .available-slot {
  display: none;
}
.week-schedule-box.with-bg-hover .week-box:hover{
  background-color: var(--color-white-03);
  transition: var(--transiton-3s);
  cursor: pointer;
}
/* add benchmark */
.add-benchmark-wrapper .becnchmark-left-absolute-div {
  position: absolute;
  width: 250px;
  top: 0;
  bottom: 0;
  position: absolute;
  width: 250px;
  height: calc(100% - 89px);
}
.add-benchmark-wrapper .becnchmark-left-absolute-div.modalWeeklytab{
  width: 25%;
  height: 100%;
}
.comman-contnet-wrapper .add-benchmark-wrapper {
  height: calc(100% - 50px);
}
.benchmark-left {
  width: 250px;
  background-color: var(--color-white-03);
}
.benchmark-right {
  width: calc(100% - 250px);
  padding-left: 50px;
}
.benchmark_div_height {
  height: 70px;
  display: flex;
  justify-content: center;
}
.benchmark_div_height .with-margin-15 {
  margin: auto 15px;
}

.benchmark_div_height input {
  margin: auto 15px;
}
.benchmark-right .close-icon-wrapper{
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
/* wallet */
.wallet-box-main .wallet-box {
  min-height: 90px;
}
.wallet-box-main .wallet-box .bottom-walltet-text {
  padding-top: 30px;
}
/* my space new */
.access-box-main {
  padding: 10px;
}
/* premium subscription */
.premium-sub-main-box {
  padding: 10px;
  margin-top: 15px;
  height: calc(100% - 150px);
}
.premium-sub-main-box.h100px{
  height: calc(100% - 100px);
}

.premium-sub-main-box.h_66{
  height: calc(100% - 66px);
}
.premium-sub-main-box.p-15 {
  padding: 15px;
}
.premium-sub-main-box .space-wrapper {
  padding: 10px 0px;
}
.premium-sub-main-box .space-wrapper:first-child {
  padding-top: 0px;
}
.task-list-card-parent.active .task-list-card.with-padding {
  border: 1px solid var(--color-green-50);
}
.task-list-card.with-padding {
  padding: 10px;
  border: 1px solid var(--color-white-07);
}
.task-list-card.min-h175 {
  min-height: 175px;
}
.calednar-dropdown-modal {
  background-color: var(--calendar-modal-color);
  border: 1px solid var(--color-white-07);
}
.calednar-dropdown-modal .small_modal_wrapper .top_detail {
  position: relative;
}

.calednar-dropdown-modal .small_modal_wrapper .top_detail::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-white-05);
}
/* stand alone page */
.stand-alone-left-main{
  /* width: 34%; */
  width: 40%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1050;
}

.stand-alone-left-main.docmodal{
  /* width: 15%; */
  width: 300px;
}

.stand-alone-left-main .stand-alone-top{
  padding: 5% 8% 0% 13%;
  /* padding: 10% 10% 0% 15%; */
}
.stand-alone-left-main .logo-img img , .stand-alone-left-main .logo-img .standalonelogo{
  height: 50px;
  width: auto;
}
.stand-alone-top .custom-select-menu .react-select__dropdown-indicator{
  background-image: url("../images/down-arrow.svg");
  opacity: 1;
  background-position: center right 8px;
  background-size: 10px;
}
.stand-alone-top .custom-select-menu .react-select__menu, .stand-alone-top .custom-select-menu .react-select__menu-portal .react-select__menu{
  /* width: 100px; */
    /* left: -20px; */
    /* top: 40px; */
    top:0px;
    width: 55px;
}
.stand-alone-left-main .detail-title-part{
  padding: 40px 0px  20px 0px;
}
.stand-alone-left-main .detail-title-part .subtitle-part{
  padding: 20px 0px;
}
.stand-alone-left-main .mid-section-img{
  height: 350px;
  width: 100%;
}
.stand-alone-left-main .mid-section-img{
  width: 385px;
  height: 287px;
}
.stand-alone-left-main .mid-section-img .Provider-Img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.stand-alone-left-main .register-btn{
  padding:15px 0px;
}
.stand-alone-left-main .stand-alone-bottom{
  margin-top: auto;
  padding: 15px 0px;
  border-top: 1px solid var(--color-white-07);
}
/* Schedule  AI */
.daily-schedule-main{
  /* width: 720px; */
  width: 850px;
  margin: auto;
}
.daily-schedule-main.w-800px{
  width: 850px;
  position: relative;
}
.c-top-calendar.daily-schedule-main.w-800px{
  padding: 0 15px;
}
.modal-open .c-top-calendar.daily-schedule-main.w-800px{
  padding: 0 0;
}
.daily-schedule-main .Litecardwrapper{
  overflow: visible;
}
.modal-open .daily-schedule-main{
  width: auto;
  margin: 0;
}
.schedule-header-main{
  background-color: var(--calendar-modal-color);
  padding: 15px;
}

.schedule-header-main .schedule-mid-header{
  margin: 0px 20px;
  padding: 0px 20px;
  position: relative;
  width: calc(100% - 160px);
}
.schedule-header-main .schedule-mid-header:before{
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 80%;
  width: 1px;
  background-color: var(--color-white-10);
}
.schedule-header-main .lock-mid-header{
  width: calc(100% - 40px);
  
}
.schedule-header-main .lock-mid-header .schedule-header-title {
  margin: 0px 20px;
  padding: 0px 20px;
  position: relative;
  width: calc(100% - 160px);
}
.lock-screen-main-header .lock-mid-header{
  width: calc(100% - 120px);
  padding: 0px 15px;
  border-right: 1px solid var(--color-white-10);
  border-left: 1px solid var(--color-white-10);
}
.lock-screen-main-header .lock-left-header{
  /* width: 80px; */
  margin-right: 10px;
}
.lock-screen-main-header .lock-right-header{
  margin-left: 15px;
}
.lock-screen-main-header .lock-right-header:hover{
  background-color: var(--color-white-07);
}
.lock-screen-main-header .lock-mid-header .schedule-header-title{
  width: calc(100% - 55%);
  padding-right: 10px;
}
/* .lock-screen-main-header .lock-mid-header .schedule-right-header{
  width: 55%;
} */
.lock-screen-main-header .lock-screen-slider{
  background-color: var(--lock-slider-bg);
}
.schedule-header-main .schedule-mid-header:after{
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 80%;
  width: 1px;
  background-color: var(--color-white-10);
}

.schedule-header-main .schedule-slider-wrapper{
  padding-left: 20px;
  width: calc(100% - 140px);
}
.daily-schedule-main .schedule-content-wrapper{
  padding: 20px;
}
.daily-schedule-main .schedule-progress-wrapper{
  padding: 25px 0px;
}
.daily-schedule-main .schedule-progress-wrapper .progress-box{
  padding: 2px;
  background: var(--color-white-10);
  flex-grow: 1;
  margin-right: 5px;
}
.daily-schedule-main .schedule-progress-wrapper .progress-box:first-child{
  border-radius: 3px 0px 0px 3px;
}
.daily-schedule-main .schedule-progress-wrapper .progress-box:last-child{
  border-radius: 0px 3px 3px 0px;
}
.daily-schedule-main .schedule-progress-wrapper .progress-box.active{
  background: var(--color-gradient);
}
.wallet-task-title-sticky{
  position: sticky;
  top:0;
  background: var(--premium-bg-transparent);
}
.ai-chat-main .ai-lchat-list{
  padding-top: 20px;
}
.ai-chat-main .ai-lchat-list .ai-text-chat{
  padding: 10px 0px;
}
.lock-screen-main-header .lock-left-header.custom-datepicker.cursor_hide .react-datepicker-wrapper .react-datepicker__input-container input{
  opacity: 0;
}


@media screen and (min-width: 1025px) and (max-width: 1500px){
.invoice-wallet-card .calendar-input-small{
  max-width: 115px;
}
.invoice-wallet-card .task-right-static-options .invoice-amount .c-font.f-14{
  font-size: 12px;

}
.invoice-wallet-card .task-right-static-options .invoice-amount .m_w50{
  max-width: 40px;
}
.invoice-wallet-card .calendar-input-small .h20w20{
  margin: auto;
  height: 100%;
}
.invoice-wallet-card .calendar-input-small .h20w20 svg{
  width: 14px;
    display: flex;
    align-items: center;
    margin: auto;
}
/* .invoice-wallet-card .calendar-input-small .custom-datepicker.w-90.datepicker-input .react-datepicker-wrapper .react-datepicker__input-container input{
  max-width: 95px;
  width: 95px;
} */
.invoice-wallet-card .calendar-input-small{
  min-width: 65px;
  font-size: 10px;
}
.invoice-wallet-card.task-list-card.list-view .task-left .task-top-part .task-list-name.max-w300{
  max-width: 200px;
  font-size: 11px;
}
.invoice-wallet-card .task-right-static-options .c-font.f-12{
   font-size: 10px;
}
.invoice-wallet-card .calendar-input-small .custom-datepicker.w-90.datepicker-input .react-datepicker-wrapper .react-datepicker__input-container input{
  
}
}
.absoluteTaskName{
  /* position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 12; */
}