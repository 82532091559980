svg.comman-icons{
    stroke: var(--base-font-color);
    transition: var(--transiton-3s);
    margin: auto;
 }
 svg.comman-icons.with-path-stroke path{
    stroke: var(--base-font-color);
 }
 svg.comman-icons.with-fill{
    fill: var(--base-font-color);
 }
 .light-theme svg.comman-icons.white{
    stroke: var(--color-black)
 }
 .light-theme svg.comman-icons.white:hover{
    stroke: var(--color-green);
 }
 .light-theme .comman-round-box:hover svg.comman-icons{
    /* stroke: var(--icone-hover-color-black-2); */
     transition: var(--transiton-3s);
 }
 .light-theme .comman-round-box:hover svg.comman-icons.path-warning {
    stroke: none;
 }
 .light-theme svg.stroke-transparent.comman-icons:hover{
    stroke:transparent;
 }
 .light-theme .comman-round-box:hover svg.comman-icons.lt-black,
 .light-theme .on-hover-active-toggle-img:hover svg.comman-icons.lt-black{
    stroke: var(--color-black);
 }
 /* .light-theme .on-hover-active-toggle-img:hover svg.comman-icons{
    stroke: var(--icone-hover-color-black-2);
  } */
  .light-theme .on-hover-active-toggle-img:hover svg.comman-icons.stroke-transparent:hover{
    stroke: transparent;
  }  
 /* .light-theme svg.comman-icons:hover{
    stroke: var(--icone-hover-color-black-2);
 } */

.light-theme .on-hover-active-toggle-img:hover svg.comman-icons.white{
    stroke: var(--color-green);
 }
.on-hover-active-toggle-img.active svg.comman-icons,
.tab-wrapper.active svg.comman-icons{
    stroke: var(--color-green);
 }
 svg.comman-icons.HW8{
    height: 8px;
    width: 8px;
}
 svg.comman-icons.HW10{
     height: 10px;
     width: 10px;
 }
 svg.comman-icons.HW12{
     height: 12px;
     width: 12px;
 }
 svg.comman-icons.HW14{
     height: 14px;
     width: 14px;
 }
 svg.comman-icons.HW18{
     height: 18px;
     width: 18px;
 }
 svg.comman-icons.HW20{
     height: 20px;
     width: 20px;
 }
 svg.comman-icons.HW22{
     height: 22px;
     width: 22px;
 }
 svg.comman-icons.HW24{
     height: 24px;
     width: 24px;
 }
 svg.comman-icons.HW26{
     height: 26px;
     width: 26px;
 }
 svg.comman-icons.HW28{
     height: 28px;
     width: 28px;
 }
 svg.comman-icons.HW30{
     height: 30px;
     width: 30px;
 }
 svg.comman-icons.HW32{
    height: 32px;
    width: 32px;
}
 svg.comman-icons.HW40{
    height: 40px;
    width: 40px;
}
 svg.comman-icons.HW50{
    height: 50px;
    width: 50px;
}
svg.comman-icons.HW60{
    height: 60px;
    width: 60px;
}
svg.lt-black.comman-icons{
    stroke: var(--base-font-color);
}
.light-theme svg.lt-black.comman-icons{
    stroke: var(--color-black);
}
svg.black-l-white.comman-icons{
    stroke: var(--color-black);
}
.light-theme svg.black-l-white.comman-icons{
    stroke: var(--color-black);
}
/* green-l-black */
svg.green-l-black.comman-icons path{
    fill:var(--color-green);
}
.light-theme svg.green-l-black.comman-icons path{
    fill:#1c3545;
}
svg.black.comman-icons{
    stroke: var(--color-black);
}
svg.fill-transparent.comman-icons{
    fill:transparent;
}
svg.path-fill-transparent.comman-icons path{
    fill:transparent;
}
svg.fill-white.comman-icons{
    fill:var(--base-font-color);
}
svg.path-white.comman-icons path{
    fill:var(--base-font-color);
}
svg.path-warning.comman-icons path{
    fill:var(--color-warning);
}
svg.warning.comman-icons{
    stroke:var(--color-warning);
}
/* .light-theme svg.path-warning.comman-icons path{
    fill:var(--color-green);
} */
svg.stroke-transparent.comman-icons{
    stroke: transparent !important;
}
svg.path-white-lt-black.comman-icons path{
    fill:var(--base-font-color);
}
.light-theme svg.path-white-lt-black.comman-icons path{
    fill:var(--base-font-color);
}
.light-theme .on-hover-active-toggle-img:hover svg.comman-icons.stroke-transparent{
    stroke: transparent;
}
.light-theme .on-hover-active-toggle-img:hover svg.comman-icons.path-white-lt-black path{
    fill: var(--icone-hover-color-black-2);
}
.show[aria-expanded="true"] .on-hover-active-toggle-img svg.comman-icons,
.show[aria-expanded="true"].on-hover-active-toggle-img svg.comman-icons,
/* .dropdown-menu .dropdown-item:hover svg.comman-icons, */
.dropdown-menu .dropdown-item.active svg.comman-icons{
    stroke: var(--color-green);
    transition: var(--transiton-3s);
}
.dropdown-menu .dropdown-item.active svg.c-icons{
    color: var(--color-green);
    transition: var(--transiton-3s);
}
.show[aria-expanded="true"] .on-hover-active-toggle-img,
.show[aria-expanded="true"].on-hover-active-toggle-img {
    color: var(--color-green);
}

svg.c-icons{
    margin:auto;
}
svg.c-icons.green-light-grey[fill]{
    color:var(--color-green);
}
.light-theme svg.c-icons.green-light-grey[fill]{
    /* color:rgb(180,180,180); */
}
.light-theme svg.c-icons.black-l-white[fill]{
    color: var(--color-white);
}
.light-theme svg.c-icons.black-l-white.lt-black[fill]{
    color: var(--color-black);
}
svg.c-icons[fill]{
    /* color: var(--color-white); */
    color: var(--base-font-color);
}
.btn-primary svg.c-icons[fill]{
    /* color: var(--color-white); */
    color: var(--btn-primary-color);
}
svg.c-icons.black-l-white[fill]{
    /* color: var(--color-white); */
    color: var(--color-black);
}
svg.c-icons.black[fill]{
    color: var(--color-black);
}
.on-hover-active-toggle-img:hover svg.c-icons[fill] , .footer-nav-linksnew .on-hover-active-toggle-img.active svg.c-icons[fill] {
    color: var(--color-green);
}
svg.c-icons.color-green[fill]{
    color: var(--color-green);
}
svg.c-icons.color-blue[fill]{
    color: var(--color-blue);
}
svg.c-icons.color-orange[fill]{
    color: var(--color-warning)!important;
}
svg.c-icons.warning[fill]{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.light-theme .company-profile-page .left-content-part.bg-transparent{
    background-color: var(--base-header-color) !important;
}
.light-theme .right-content-part.width-calc-300.with-margin .custom-accordian-main .accordian-header-box{
    /* background-color: transparent; */
    border:1px solid var(--blue-border);
}


.light-theme .onboarding-content-scroll-wrapper .base-body-color{
    /* background-color: var(--header-bg); */
}
.light-theme .onboarding-content-scroll-wrapper .comman-white-box.base-body-bg{
    /* background-color: var(--header-bg); */
}
.light-theme .toast-main .toast .toast-header{
    background-color: var(--base-header-color);
}
.light-theme .toast-main .toast .toast-body{
    background-color: var(--header-bg);
}
.light-theme .office-list-main .office-box.left-view-more-btn{
    color: var(--base-font-color);
}
/* .light-theme .btn.btn-primary:focus{
    color: var(--base-modal-bg);
} */
.icon-h-18 svg{
    height: 18px;
    width: 18px;
}