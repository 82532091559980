.multi_inner_wrapper .comman-image-box {
  margin-left: -10px;
  background-color: #263848;
  position:relative;
  /* outline: 3px solid #263848; */
  /* outline: 3px solid #2c4250; */
}
.multi_inner_wrapper .comman-image-box::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border: 3px solid #2c4250;
  border-radius: 50%;
  z-index: 0;
}
.multi_inner_wrapper .comman-image-box > div,
.multi_inner_wrapper .comman-image-box > a{
  z-index: 1;
}
.multi_inner_wrapper .comman-image-box.border-2 {
  outline: 2px solid #2c4250;
}
.multi_inner_wrapper.with_ms_5 .comman-image-box {
  margin-left: -5px;
}
.multi_image_wrapper.multi-image-min-width {
  min-width: 90px;
}
.multi_inner_wrapper.with-min-width {
  justify-content: flex-end;
}
.multi_inner_wrapper.with_minus .comman-image-box {
  margin-left: -6px;
}
.multi_inner_wrapper.min-height-25 {
  min-height: 25px;
}
.comman-image-box {
  background-color: var(--color-white-05);
}
.comman-image-box.with-close {
  position: relative;
}
.comman-image-box.with-close .close-circle {
  position: absolute;
  top: -3px;
  right: -6px;
  height: 14px;
  width: 14px;
  background-color: #1c3544;
  display: flex;
  border-radius: 500px;
  border: 1px solid var(--color-white-20);
}
.comman-image-box.with-close .close-circle img {
  margin: auto;
  width: 10px;
  height: auto;
}
.multi_inner_wrapper.ml_2 .comman-image-box {
  margin-left: -10px;
}
.multi_inner_wrapper.white_bg .comman-image-box {
  background-color: var(--color-white-1);
}
.multi_inner_wrapper .comman-image-box.white-bg {
  background-color: var(--color-white-1);
}
.multi_inner_wrapper .comman-image-box:first-child {
  margin-left: 0px;
}
.multi_inner_wrapper .comman-image-box:nth-child(2) {
  z-index: 2;
}
.multi_inner_wrapper .comman-image-box:nth-child(3) {
  z-index: 3;
}
.multi_inner_wrapper .comman-image-box:nth-child(4) {
  z-index: 4;
}
.multi_inner_wrapper .comman-image-box:nth-child(5) {
  z-index: 5;
}
.multi_inner_wrapper .comman-image-box:nth-child(6) {
  z-index: 6;
}
.multi_inner_wrapper .comman-image-box:nth-child(7) {
  z-index: 7;
}
.multi_inner_wrapper .comman-image-box:nth-child(8) {
  z-index: 8;
}
.multi_inner_wrapper .comman-image-box:nth-child(9) {
  z-index: 9;
}
.multi_inner_wrapper .comman-image-box:nth-child(10) {
  z-index: 10;
}
.multi_inner_wrapper .comman-image-box:nth-child(11) {
  z-index: 11;
}
.multi_inner_wrapper .comman-image-box:nth-child(12) {
  z-index: 12;
}
.z-index-12 {
  z-index: 12;
}
.z-index-12imp {
  z-index: 12!important;
}
/*with sepeartor*/
.with_separator_10 {
  padding: 0px 10px;
  position: relative;
}
.with_separator_10.withseperatorabs:before{
  top: 14px;
  left: -20px;
  right: auto;
}
.with_separator_10.with-height {
  width: 1px;
  height: 15px;
  padding: 0;
  margin-bottom: 33px;
}
.with_separator_10.with-height:before {
  height: 100%;
  width: 100%;
  transform: none;
  right: auto;
}
.with_separator_10.with-left-20:before{
  left: -20px;
  height: 16px;
}
.with_separator_10.before-block:last-child:before {
  display: block;
}
.with_separator_10.before-dnone:before {
  display: none;
}
.with_separator_10:last-child:before {
  display: none;
}
.with_separator_10:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  /* background-color: var(--color-white-10); */
  background-color: rgba(255,255,255,0.08);
  width: 1px;
  height: 10px;
}
.with_separator2_10 {
  padding: 0px 10px;
  position: relative;
}
.with_separator2_10:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  /* background-color: var(--color-white-10); */
  background-color: rgba(255,255,255,0.08);
  width: 1px;
  height: 10px;
}
.with_separator_10.top_2:before{
  top: 2%;
  transform: translateY(0%);
}
.with_separator_10.with-left-0:before {
  left: 0;
  right: auto;
}
/*comman action icon*/
.comman_action_icon {
}
.comman_action_icon.step-with-absolute-pos {
  position: absolute;
  bottom: -43px;
  right: 0;
}
.comman_action_icon.with-absolute-bottom-160 {
  position: absolute;
  bottom: 160px;
  right: 10px;
}
.comman_action_icon.with-top-right-pos {
  position: absolute;
  top: 0;
  right: 0;
}
.comman_action_icon .action_icon {
  height: var(--space-25);
  width: var(--space-25);
  /* line-height: 0; */
  display: flex;
}
.comman_action_icon .action_icon.w55 {
  width: 55px;
}
.comman_action_icon .action_icon.w55h55 {
  height: 55px;
  width: 55px;
}
.comman_action_icon .action_icon.h46 {
  height: 46px;
}
.comman_action_icon .action_icon.with-outline-5px {
  outline: 5px solid var(--color-white-50);
}
.comman_action_icon .action_icon.h20w20 {
  height: var(--space-20);
  width: var(--space-20);
}
.comman_action_icon .action_icon.h30w30 {
  height: var(--space-30);
  width: var(--space-30);
}
.comman_action_icon .action_icon.h40w40 {
  height: var(--space-40);
  width: var(--space-40);
}
.comman_action_icon .action_icon.h45w45 {
  height: 45px;
  width: 45px;
}
.comman_action_icon .action_icon.h50w50 {
  height: var(--space-50);
  width: var(--space-50);
}
.comman_action_icon .action_icon:hover,
.left-view-more-btn:hover .action_icon {
  background-color: var(--color-white-05);
  border-radius: 3px;
  transition: var(--transiton-3s);
}
.comman_action_icon .action_icon.without-bg:hover {
  background-color: transparent;
  transition: var(--transiton-3s);
}
.comman_action_icon .action_icon.with_bg {
  background-color: var(--color-white-05);
  border-radius: 3px;
  border: 1px solid transparent;
}
.comman_action_icon .action_icon.with_bg.with-comment-close {
  background-color: var(--color-white-05-solid);
}

.comman_action_icon .action_icon.with_bg:hover {
  background-color: var(--color-white-10);
}
.comman_action_icon .action_icon.with-left-panel-bg {
  background: var(--left-panel-bg);
}
.comman_action_icon .action_icon.with_bg_03 {
  background-color: var(--color-white-03);
}
.comman_action_icon .action_icon img {
  margin: auto;
}
.comman_action_icon img {
}
.comman_action_icon span {
  padding-left: 5px;
}
.img-height-20 {
  height: 20px;
  line-height: 0;
  width: auto;
  max-width: inherit;
  max-height: inherit;
}
/*prgoress bar*/
.progress-slider {
}
.progress-slider .progress {
  flex-grow: 1;
  background: var(--color-white-05);
  border-radius: 40px;
  height: 5px;
}
.progress-slider .progress.w_100 {
  width: 55px;
}
.progress-slider .progress .progress-bar {
  /* background: var(--color-gradient); */
  background: var(--color-green);
  height: 100%;
  border-radius: 30px;
}
.progress-slider .progress .progress-bar.bg-white40 {
  background-color: var(--color-white-40);
}
.progress-slider .progress .progress-bar.progress-bar-color-blue {
  background: var(--color-blue);
}
.progress-slider .progress-text {
  width: 25px;
  text-align: right;
}
/*top calendar*/
.c-top-calendar {
  height: 60px;
  z-index: 8;
  position: relative;
  /* margin-top: 8px; */
}
body.modal-open  .c-top-calendar {
  z-index: 6;
}
.c-top-calendar .left-box:hover{
  background-color: var(--color-white-10);
}
.c-top-calendar .left-box {
  background-color: var(--base-header-color);
}
.c-top-calendar .left-box .unread-badge {
  right: 8px;
  top: 3px;
}
.c-top-calendar .calendar-date-wrapper {
  padding: 0px 5px;
  /* padding: 0px 4px; */
  flex-grow: 1;
  width: calc(100% - 130px);
}
.task-modal-offcanvas-open .calendar-date-wrapper .calendar-date .cal-date-box.cal-date-box-view-toggle{
  display: none;
  flex-grow: 1;
}
.task-modal-offcanvas-open .calendar-date-wrapper .calendar-date .cal-date-box.cal-date-box-view-toggle:nth-child(5){
  display: inline-flex!important;
}
.task-modal-offcanvas-open .calendar-date-wrapper .calendar-date .cal-date-box.cal-date-box-view-toggle:nth-child(7){
  display: inline-flex!important;
}
.task-modal-offcanvas-open .calendar-date-wrapper .calendar-date .cal-date-box.cal-date-box-view-toggle:nth-child(9){
  display: inline-flex!important;
}
.c-top-calendar .calendar-date-wrapper .cal-date-box {
  padding: 0px var(--space-15);
  height: 60px;
  display: inline-flex;
  justify-content: center;
  background-color: var(--base-header-color);
  border-radius: 0px;
  margin: 0px 5px;
  /* margin: 0px 3px; */
  width: calc(14.28% - 6px);
  position: relative;
  border: 1px solid transparent;
  align-items: center;
  transition: var(--transiton-3s);
  border-radius: 3px;
}
.c-top-calendar .calendar-date-wrapper .cal-date-box:hover {
  background-color: var(--color-white-10);
}
.c-top-calendar .calendar-date-wrapper a.active {
}
.c-top-calendar .calendar-date-wrapper a .date-text {
  font-weight: var(--fw-bold);
}
.c-top-calendar .calendar-date-wrapper a .date-details {
  padding-left: 15px;
}
.c-top-calendar .calendar-date-wrapper a .date-details .day-name {
}
.c-top-calendar .calendar-date-wrapper a .date-details .date-task-dots {
  line-height: 0.5;
}
.c-top-calendar .calendar-date-wrapper a .date-details .date-task-dots span {
  margin-right: 5px;
  /* margin-top: 5px; */
}
.c-top-calendar
  .calendar-date-wrapper
  a
  .date-details
  .date-task-dots
  span:last-child {
  margin-right: 0px;
}
/*footer*/
footer.z-index-7 {
  position: fixed;
  z-index: 7;
}
.footer-add-btn.with-position {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.footer-add-btn.with-position.right-65px{
  right: 65px;
}
footer .footer-contact-img {
}
.comman-round-box.bg-gradient-green {
  background: var(--color-gradient);
}
.comman-round-box.on-active-badge:before {
  content: "";
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: var(--color-green);
  position: absolute;
  right: 0px;
  top: 2px;
  border: 4px solid var(--base-body-color);
}
footer .footer-links {
  /* position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 380px;
  border-radius: 50px;
  background-color: var(--base-header-color);
  height: 60px; */
  /* box-shadow: var(--footer-action-icon-box-shadow); */
  background-color: var(--base-body-color);
}
footer .footer-links.with-bottom-pos {
  bottom: 60px;
}
footer .footer-links li {
  transition: var(--transiton-3s);
}
footer .footer-links li:hover .absolute_star{
  display: inline-flex;
}
footer .footer-links li.mid-footer-link {
  /* width: 33.33%; */
}
footer .footer-links li.mid-footer-link.active svg.path-white.comman-icons path {
  fill: var(--color-green);
}
footer .footer-links li a {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
footer .footer-links li:hover span {
  color: var(--color-green);
  transition: var(--transiton-3s);
}
footer .footer-links li a span {
  /* padding-top: 3px; */
}
footer .footer-links .footernav a{
  border-radius: 30px;
}
.floor-filter {
  margin-bottom: 10px;
}
.footer-add-btn {
  /* margin-bottom: 10px; */
}
.footer-add-btn.with-upside-pos {
  position: absolute;
  right: 0;
  bottom: 70px;
}
.on-hover-active-toggle-img {
  transition: var(--transiton-3s);
}
.on-hover-active-toggle-img.active .hover-span {
  color: var(--color-green);
}
.on-hover-active-toggle-img img {
  transition: var(--transiton-3s);
}
.on-hover-active-toggle-img:hover .default-img {
  display: none;
  transition: var(--transiton-3s);
}
.on-hover-active-toggle-img:hover .active-img {
  display: block;
  transition: var(--transiton-3s);
}
.on-hover-active-toggle-img .hover-span {
  transition: var(--transiton-3s);
}
.on-hover-active-toggle-img:hover .hover-span {
  color: var(--color-green);
  transition: var(--transiton-3s);
}
.inprogress.with-circle:hover .office-list-circle .hover-span {
  color: var(--color-blue);
  transition: var(--transiton-3s);
}
.done.with-circle:hover .office-list-circle .hover-span {
  color: var(--color-green);
  transition: var(--transiton-3s);
}
.with-circle:hover .office-list-circle .hover-span {
  color: var(--base-font-color);
  transition: var(--transiton-3s);
}
.on-hover-active-toggle-img:hover svg.comman-icons {
  stroke: var(--color-green);
  transition: var(--transiton-3s);
}
.on-hover-active-toggle-img:hover .dropdown-menu svg.comman-icons{
  stroke: var(--base-font-color);
}
.on-hover-active-toggle-img:hover .dropdown-menu .on-hover-active-toggle-img:hover svg.comman-icons{
  stroke: var(--color-green);
}
.on-hover-active-toggle-img:hover svg.comman-icons.stroke-transparent {
  stroke: transparent;
}
.on-hover-active-toggle-img:hover svg.comman-icons.path-white-lt-black path {
  fill: var(--color-green);
}
/*footer animation*/
.footer-mid-btn-animation {
  height: 70px;
  width: 70px;
  display: flex;
}
.footer-mid-btn-animation.h50w50 {
  height: 50px;
  width: 50px;
}  
.footer-mid-btn-animation.h35w40 {
  height: 35px;
  width: 40px;
}  
.footer-mid-btn-animation div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  height: 100%;
  width: 100%;
}
.footer-mid-btn-animation .hello_box {
  margin: auto;
}
.footer-mid-btn-animation .hello_box.active svg path {
  fill: var(--color-green);
}
.footer-mid-btn-animation .footer-mid-circle {
  transition: all 1200ms;
  margin: auto;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);
}
.footer-mid-btn-animation .footer-mid-circle.bg-green-gradient {
  background-color: var(--color-green);
  opacity: 0.05;
}
.footer-mid-btn-animation .footer-mid-circle.circle-1 {
  border-radius: 40% 42% 45% 55%;
  animation: animate1 5200ms infinite linear;
  height: 90%;
  width: 90%;
}
.footer-mid-btn-animation .footer-mid-circle.circle-2 {
  border-radius: 55% 45% 55% 45%;
  animation: animate2 5200ms infinite linear;
  height: 75%;
  width: 75%;
}
.footer-mid-btn-animation .footer-mid-circle.circle-3 {
  border-radius: 55% 45% 55% 45%;
  animation: animate1 5200ms infinite linear;
  height: 60%;
  width: 60%;
}
.footer-mid-btn-animation .footer-mid-circle.circle-4 {
  border-radius: 55% 45% 55% 45%;
  animation: animate2 5200ms infinite linear;
  height: 45%;
  width: 45%;
}
@-webkit-keyframes animate1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes animate2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-720deg);
  }
}
.office-list-main {
  /* scrollbar-gutter: stable; */
}
/*scroll*/
body ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
body .react-select__menu ::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}
body .simplebar-scrollbar:before {
  background: var(--color-white-03);
}
/* Track */
body ::-webkit-scrollbar-track {
  background: var(--base-header-color);
}
/* Handle */
body ::-webkit-scrollbar-thumb {
  background: var(--color-white-03);
  border-radius: 10px;
}
/* Handle on hover */
body ::-webkit-scrollbar-thumb:hover {
  background: var(--color-white-03);
  transition: var(--transiton-3s);
}
/* Track */
.iframe-scroll ::-webkit-scrollbar-track {
  background: var(--base-header-color);
}
/* Handle */
.iframe-scroll ::-webkit-scrollbar-thumb {
  background: var(--color-white-03);
  border-radius: 10px;
}
/* Handle on hover */
.iframe-scroll ::-webkit-scrollbar-thumb:hover {
  background: var(--color-white-03);
  transition: var(--transiton-3s);
}
/*table lisiting accordian*/
.table-listing-accordian {
}
.table-listing-accordian .accordion-item {
  background: transparent;
  border: 0;
  color: inherit;
}
.table-listing-accordian .accordion-header {
  position: relative;
  padding: 10px;
}
.table-listing-accordian .accordion-button {
  background: transparent;
  box-shadow: none;
  border-bottom: 1px solid var(--color-white-10);
  padding: 0;
}
.table-listing-accordian .accordion-button.with-border {
  border-bottom: 1px solid transparent;
  padding-bottom: 0 !important;
}
.table-listing-accordian .accordion-button.with-border.collapsed {
  border-bottom: 1px solid var(--color-white-10);
  padding-bottom: 10px !important;
}
.table-listing-accordian .accordion-button.expand-with-bg {
  position: initial;
}
.table-listing-accordian .accordion-button.expand-with-bg:before {
  content: "";
  background-color: var(--color-white-03);
  background-image: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: var(--transition-4s);
}
.table-listing-accordian .accordion-button.collapsed.expand-with-bg:before {
  opacity: 0;
}
.table-listing-accordian .accordion-button.expand-with-bg:before {
  opacity: 1;
}
.table-listing-accordian .accordian-left-title {
  flex-grow: 1;
  width: calc(100% - 150px);
}
.table-listing-accordian .accordion-header .accordion-button {
  padding-top: 0;
  padding-bottom: 10px;
  padding-left: 0;
  color: var(--base-font-color);
  border-radius: 0;
}
.table-listing-accordian .accordion-header .accordion-button .task-title {
  padding: 0px 10px;
}
.table-listing-accordian .accordion-item .accordion-body {
  background: var(--color-white-03);
  flex-grow: 1;
  height: 100%;
}
.table-listing-accordian .accordion-button::after {
  background-image: url(../images/ic-caret-down-white.svg);
}
.table-listing-accordian .accordion-button.arrow-left::after {
  margin-left: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.table-listing-accordian .accordion-button.arrow-none::after {
  display: none;
}
.table-listing-accordian .accordion-header.arrow-none {
  border: 0;
}
.table-listing-accordian .accordion-header.arrow-none:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border: var(--color-);
}
/*table lisintg*/
.table-listing-main {
  background: var(--color-white-03);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  display: inline-block;
}
.table-listing-main:last-child {
  margin-bottom: 0px;
}
.table-listing-main.w-400 {
  width: 400px;
}
.table-listing-main .left-task-detail {
  padding-left: 10px;
  width: 100%;
}
.table-listing-main .left-task-detail .task-name-img {
}
.table-listing-main .left-task-detail .task-name-img .task-name {
  padding-left: 10px;
  max-width: 100px;
}
.table-listing-main .left-task-detail .task-date-detail {
  padding: 15px 0px;
  border-bottom: 1px solid var(--color-white-10);
}
.table-listing-main .left-task-detail .bottom-part {
  padding-top: 15px;
}
.table-listing-main .right-task-detail.margin_left_minus {
  margin-left: -135px;
}
/*for kanban view*/
.table-listing-accordian .kanban-view {
  display: flex;
  justify-content: space-between;
  transition: var(--transiton-3s);
}
.kanban-view.horizontal-scroll{
  overflow-x: scroll !important;
}
.table-listing-accordian .kanban-view .table-listing-main {
  width: 100%;
}
/*for list view*/
.table-listing-main.list-view {
}
.table-listing-main.list-view {
  width: 100%;
}
.table-listing-main.list-view .table-list-wrapper {
  align-items: center;
}
.table-listing-main.list-view .left-task-detail {
  display: flex;
  align-items: center;
}
.table-listing-main.list-view .left-task-detail .top-part {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.table-listing-main.list-view .table-list-progress {
}
.table-listing-main.list-view .comman-space {
  margin: 0 5%;
}
.table-listing-main.list-view .table-list-attechment {
}
.table-listing-main.list-view .left-task-detail .task-name-img .task-name {
}
.table-listing-main.list-view .left-task-detail .top-part .task-date-detail {
  padding: 0px;
  border-bottom: 0px;
}
.table-listing-main.list-view .left-task-detail .bottom-part {
  padding-top: 0px;
}
.table-listing-main.list-view .right-task-detail.margin_left_minus {
  margin-left: 0;
}
/*list view width css*/
.table-listing-main.list-view .dreg-width-calc {
  width: calc(100% - 10px);
  align-items: center;
  justify-content: space-between;
}
.table-listing-main.list-view .left-task-detail {
  width: calc(100% - 150px);
}
.table-listing-main.list-view .left-task-detail .top-part {
}
.table-listing-main.list-view .left-task-detail .task-name-img {
}
.table-listing-main.list-view .left-task-detail .task-name-img .task-name {
  max-width: initial;
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .table-listing-main.list-view .right-task-detail.margin_left_minus {
    margin-left: 3%;
  }
}
.btn.btn-white-outline {
  border: var(--color-white-10) 1px solid;
  border-radius: 3px;
}
/* Comman Accordian Style  */
.custom-accordian-main .accordion-button:not(.collapsed) {
  background-color: transparent;
  border-bottom: transparent 1px solid;
  border-radius: 3px !important;
}
.custom-accordian-main
  .accordion-button:not(.collapsed)
  .right-accordian-header.mlminus60 {
  margin-left: -45px !important;
}
.custom-accordian-main .manage-checklist-padding {
  padding: 10px 0px;
  border-bottom: 1px solid var(--color-border);
}
.custom-accordian-main .manage-checklist-padding:last-child {
  border-bottom: 0;
}
.custom-accordian-main .accordion-collapse .accordion-button:not(.collapsed) {
  background-color: transparent;
  border-bottom: transparent 1px solid;
  border-radius: 3px !important;
}
.custom-accordian-main
  .task-sub-list.active
  .accordion-button
  .accordian-left-title {
  color: var(--color-green) !important;
}
.custom-accordian-main .accordion-button:hover .color-white-60 {
  color: var(--base-font-color);
}
.custom-accordian-main .accordion-button:hover .opacity-50 {
  opacity: 1 !important;
}
.custom-accordian-main .accordion-button .color-white-60,
.custom-accordian-main .accordion-button .opacity-50 {
  transition: var(--transiton-3s);
}
.custom-accordian-main.with-left-header-arrow .accordion-item {
  margin-bottom: 8px;
  /* padding: 0px 5px; */
  border-radius: 0 !important;
}
.custom-accordian-main.with-left-header-arrow
  .comman_action_icon
  .action_icon:hover {
  background-color: transparent;
}
.task-list-accordian.custom-accordian-main .accordion-button:not(.collapsed) {
  background-color: transparent;
  border-bottom: transparent 0px solid;
}
.task-list-accordian{
  padding: 0px 15px;
}
.task-list-accordian.custom-accordian-main .accordian-header-box {
  position: sticky;
  top: 0;
  z-index: 7;
  background-color: var(--base-body-color);
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  border-bottom: 2px solid var(--task-list-card-border);
}
.task-list-accordian.custom-accordian-main .accordian-header-box.border-bottom-color-border{
  border-bottom: 2px solid var(--task-list-card-border)!important;
}
.task-list-accordian.custom-accordian-main .accordion-collapse{
  /* margin-top: 2px; */
  border-bottom: 2px solid var(--task-list-card-border);
}
.task-list-accordian.custom-accordian-main .accordian-header-box .checkbox_accordian{
  background-color: var(--color-white-03);
  width: 100%;
}
.task-list-accordian.custom-accordian-main
  .accordian-header-box.with-accordian-bg {
  background-color: var(--premium-bg-transparent);
}
.accordion-button .caretarrow {
  transform: rotate(180deg) !important;
  margin-right: 5px !important;
}
.accordion-button:not(.collapsed) .caretarrow {
  transform: rotate(0deg) !important;
}
.task-list-accordian.custom-accordian-main .accordion-header {
  min-width: 150px;
}
.task-list-accordian.custom-accordian-main .accordion-button {
  border-bottom: transparent 0px solid;
  padding: 5px 0px!important;
}
.task-list-accordian .accordion-button {
  padding: 0 !important;
}
.task-list-accordian .accordion-button .left-task-header {
  /* background-color: var(--color-white-05); */
  padding: 10px;
  min-width: 150px;
  text-transform: uppercase;
}
.custom-accordian-main .accordion-button::after {
  background-image: none;
}
.custom-accordian-main.with-left-header-arrow.emaildetailviewaccordians
  .accordion-button::after {
  display: none;
}
.custom-accordian-main.emaildetailviewaccordians
  .accordion-item
  .accordion-body {
  padding-left: 36px;
}
.custom-accordian-main.emaildetailviewaccordians .accordion-button {
  padding-left: 0;
  padding-right: 0;
}
.custom-accordian-main.with-left-header-arrow .accordion-button::after {
  background-image: url(../images/ic-caret-down-white.svg);
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 12px;
  height: 15px;
  width: 15px;
  background-size: 15px;
  transform: translateY(-50%) rotate(0deg);
  margin-top: -2px;
}
.custom-accordian-main.with-left-header-arrow.no-image .accordion-header .title-font{
  padding-left: 0px;
}
.custom-accordian-main.with-left-header-arrow.no-image .accordion-button::after {
  display: none;

}
.custom-accordian-main.with-right-header-arrow .accordion-button::after {
  background-image: url(../images/ic-caret-down-white.svg);
  z-index: 5;
  position: absolute;
  top: 50%;
  right: 12px;
  height: 15px;
  width: 15px;
  background-size: 15px;
  transform: translateY(-50%) rotate(0deg);
}
.custom-accordian-main.with-right-header-arrow
  .accordion-button.collapsed::after {
  transform: translateY(-50%) rotate(180deg);
}
.custom-accordian-main.with-left-header-arrow
  .accordion-button.collapsed::after {
  transform: translateY(-50%) rotate(-90deg);
}
.custom-accordian-main.with-left-header-arrow .accordion-header .title-font {
  padding-left: 27px;
}
.custom-accordian-main .accordion-collapse {
  transition: 0.3s all ease;
}
.custom-accordian-main .accordion-item {
  background-color: transparent;
  border: 0px;
}
.custom-accordian-main .accordion-header {
  position: relative;
  width: 100%;
}
.custom-accordian-main .accordion-header .pill-wrapper {
  margin-right: -10px;
}
.comman_glass_effect{
  background-color: var(--color-white-10);  
  backdrop-filter: blur(10px);
}
.custom-accordian-main .accordion-header.active:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  height: 100%;
  width: 4px;
  transform: translateY(-50%);
  border-radius: 0px 500px 500px 0px;
}
.custom-accordian-main .accordion-header.active.to-do:before {
  background: var(--inprogress-status);
  z-index: 1;
}
.custom-accordian-main .accordion-header.active.orange:before {
  background: var(--color-warning);
  z-index: 1;
}
.custom-accordian-main .accordion-header.active.in-progress:before {
  background: var(--color-blue);
  z-index: 1;
}
.custom-accordian-main .accordion-header.active.not-started:before{
  background: var(--color-warning);
  z-index: 1;
}
.custom-accordian-main .accordion-header.active.ongoing:before{
  background: var(--color-yellow);
  z-index: 1;
}
.custom-accordian-main .accordion-header.active.done:before {
  background: var(--color-green);
  z-index: 1;
}
.custom-accordian-main .accordion-header.active.red:before {
  background: var(--color-red);
  z-index: 1;
}
.custom-accordian-main .left-accordian-header {
  width: calc(100% - 125px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.custom-accordian-main .accordion-button {
  background-color: transparent;
  color: var(--base-font-color);
  border-bottom: transparent 1px solid;
  box-shadow: none;
  padding: 10px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0 !important;
  transition: var(--transiton-3s);
}
.custom-accordian-main .accordian-left-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.custom-accordian-main .accordion-body {
  padding: 0;
  /* margin-top: 5px; */
}
.custom-accordian-main.accordion-body-mt0 .accordion-body {
  margin-top: 0;
}
.custom-accordian-main .accordion-body.sub-accordian {
  padding: 0;
  padding-left: 10px;
}
.custom-accordian-main
  .accordion-body.sub-accordian
  .accordion-button.padding-5px10px {
  padding: 5px 10px;
}
.custom-accordian-main .accordion-item {
  color: var(--base-font-color);
}
.custom-accordian-main .accordion-item.with-pb-15 {
  margin-bottom: 10px;
}
.custom-accordian-main .accordion-button.with-arrow-left.collapsed {
  border-bottom: var(--color-border) 1px solid;
}
.custom-accordian-main.with-left-header-arrow
  .accordion-item:last-child
  .accordian-header-box.border-bottom:has(.accordion-button.collapsed) {
  /* border-bottom: 0 !important; */
}
.custom-accordian-main .accordion-button.with-arrow-left {
  border-bottom: transparent 1px solid;
}
.custom-accordian-main .accordion-button.with-arrow-left::after {
  position: absolute;
  left: 3px;
  right: 0px;
  width: 18px;
  height: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.3s all ease;
}
.custom-accordian-main .accordion-button.with-arrow-left.collapsed::after {
  transform: rotate(-180deg);
}
*/ .custom-accordian-main .accordion-button.no-arrow-bg {
  position: initial;
  border-bottom: 0;
}
.custom-accordian-main .accordion-button.no-arrow-bg:after {
  background: 0;
  border-bottom: var(--color-border) 1px solid;
  position: absolute;
  bottom: 0;
  margin: 0;
  left: 40px;
  right: 0px;
  width: calc(100% - 40px);
  transform: 0px;
  transform: none;
  opacity: 0;
}
.custom-accordian-main .accordion-button.no-arrow-bg.collapsed:after {
  opacity: 1;
}
.custom-accordian-main .accordion-button.no-arrow-bg:after {
  opacity: 0;
}
.custom-accordian-main .accordion-button.no-arrow-bg.collapsed:before {
  background-color: transparent;
}
.custom-accordian-main .accordion-button.no-arrow-bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-white-03);
}
.custom-accordian-main .accordion-button.collapsed.no-arrow-bg {
  font-weight: 400;
}
.custom-accordian-main .accordion-button.no-arrow-bg {
  font-weight: 600;
}
.custom-accordian-main.accordian-filter.w-350 {
  width: 350px;
}
/*add tag pill with image*/
.add-tag {
  padding: 3px 5px;
}
.add-tag.with_bg {
  background-color: var(--color-white-03);
}
.add-tag input {
  padding: 0px 5px;
  max-width: 70px;
  font-size: 12px;
}
.added-tag-list {
  padding-top: 15px;
}
.added-tag-list .add-tag {
  margin-right: 10px;
}
/*comman tabs*/
.comman-tab-wrapper {
  padding-bottom: 15px;
}
.comman-tab-wrapper .tab-wrapper {
  position: relative;
  transition: var(--transiton-3s);
}
.comman-tab-wrapper .tab-wrapper {
  margin-right: 2px;
}
.comman-tab-wrapper .tab-wrapper.flex-grow-1 .tab-name {
  width: 100%;
}
.comman-tab-wrapper .tab-wrapper.active.flex-grow-1 .tab-name:before {
  width: 100%;
}
.comman-tab-wrapper .tab-wrapper.flex-grow-1:first-child .tab-name {
  margin-left: 0px;
}
.comman-tab-wrapper .tab-wrapper:first-child {
  margin-left: 0;
}
.comman-tab-wrapper .tab-wrapper.active .tab-name {
  /* color: var(--color-green); */
}
.comman-tab-wrapper .tab-wrapper.active .info-icon {
  display: block;
}
.comman-tab-wrapper .tab-wrapper.active .tab-name:before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  height: 1px;
  width: 100%;
  background-color: var(--color-green);
}
.comman-tab-wrapper .tab-wrapper .tab-name {
  position: relative;
  padding: 10px 20px;
  font-weight: var(--fw-semibold);
  border-radius: 3px 0px 0px 3px;
  font-size: 10px;
  text-align: center;
  transition: var(--transiton-3s);
  text-transform: uppercase;
  font-family: var(--title-family);
}
.comman-tab-wrapper .tab-wrapper a.tab-name:hover {
  color: var(--color-green);
}
.comman-tab-wrapper .tab-wrapper:first-child .tab-name {
  /* margin-left: -10px; */
}
.comman-tab-wrapper .tab-wrapper .info-icon {
  position: absolute;
  display: none;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
}
/*comment input box with image*/
.comment-input-box {
  position: relative;
}
.comment-input-box.absolute-position {
  position: absolute;
  top: 10px;
  left: 70px;
  width: 70%;
}
.comment-input-box.with-sticky {
  position: sticky;
  top: 0px;
  left: 0;
  background: var(--bg-white-03-solid);
  z-index: 13;
}
.comment-input-box.with-sticky.bg-update {
  background: #1d3545;
}
.comment-input-box.absolute-position.change_input_pos {
  top: 9px;
}
.comment-input-box.absolute-position.change-position {
  left: 10px;
  top: 11px;
  /* width: 77%; */
  width: 84%;
}
.comment-input-box.absolute-position.change-position .serchclose{
  position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  width: 84%;
}
.comment-input-box.absolute-position .form-control {
}
.comment-input-box.absolute-position .form-control:focus {
  background-color: var(--bg-base-header-color);
}
.comment-input-box .form-control.w-350 {
  width: 350px;
}
.comment-input-box .form-control,
textarea {
  padding-right: 50px;
}
.comment-input-box .send-arrow-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  height: 25px;
  width: 25px;
  display: flex;
  background-color: var(--color-white-03);
  border-radius: 3px;
  border:1px solid transparent
}
.comment-input-box .send-arrow-btn.right-5px,
.right-5px{
  right: 5px;
}
.comment-input-box .send-arrow-btn.right_20{
  right: 20px;
}
.comment-input-box .send-arrow-btn.primary {
  background: var(--btn-primary-bg);
  color: var(--btn-primary-color);
}
.comment-input-box .send-arrow-btn.primary svg.comman-icons {
  stroke: var(--color-black);
}
.comment-input-box .send-arrow-btn.hw-35 {
  height: 35px;
  width: 35px;
}
.comment-input-box .send-arrow-btn.with_position_right_zero {
  right: 0px;
}
.comment-input-box .send-arrow-btn img {
  margin: auto;
}
/*image preview*/
.upload-image-preview.with-padding-top {
  padding-top: 15px;
}
.upload-image-preview .upload-image {
  position: relative;
}
.upload-image-preview .upload-image.with-margin {
  margin-right: 15px;
}
.upload-image-preview .upload-close {
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 300px;
  border: 1px solid #455461;
  height: 18px;
  width: 18px;
  background-color: var(--base-body-color);
  display: flex;
}
.upload-image-preview .upload-close img {
  margin: auto;
}
.upload-image-preview.with-name .upload-image-detail {
  width: calc(100% - 40px);
  padding-left: 15px;
}
/*upload image input */
.upload-inputbox {
}
.upload-inputbox .uploadbox-text {
  padding: 5px 10px;
  font-family: var(--title-font);
  font-size: 12px;
  background-color: var(--color-white-05);
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
  cursor: pointer;
}
.upload-inputbox.with-bg-height {
  height: 230px;
  border: 2px dashed var(--color-white-10);
  background: var(--color-white-03);
}
.upload-inputbox .clip-img {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
/*input box*/
.c-input-box.h-35 {
  height: 35px;
}
.c-input-box .input-label:before {
  position: absolute;
  content: "";
  width: 14px;
  height: 14px;
  background-image: url(../images/asterisk_star.svg);
  background-repeat: no-repeat;
  background-size: 10px;
  right: -3px;
  top: 1px;
}
.c-input-box .input-label.no-asterisk:before {
  display: none;
}
.c-input-box .input-label {
  position: relative;
  margin-bottom: 8px;
  padding-right: 15px;
}
.c-input-box .input-with-button {
  position: relative;
}
.c-input-box .input-with-button .btn-with-absolute {
  position: absolute;
  bottom: 8px;
  right: 10px;
  z-index: 5;
}
.task-detail-modal .modal-header .right-modal-task-header .right-task-header {
}
/* My spaces */
.comman-contnet-wrapper .p-15 {
  padding: 15px;
}
.comman-white-box {
  background: var(--base-header-color);
  border-radius: 5px;
  padding: 15px;
  position: relative;
}
.comman-white-box.base-body-bg {
  background: var(--base-body-color);
}
.my-spaces-main .left-location-part {
  width: 360px;
}
.my-spaces-main .left-location-part .map-box {
  position: relative;
}
.my-spaces-main .left-location-part .tour-map {
  position: absolute;
  right: 5px;
  bottom: 5px;
  border-radius: 3px;
  background-color: var(--base-header-color);
  padding: 10px;
}
.my-spaces-main .left-location-part .tour-map img {
  margin-right: 10px;
}
.my-spaces-main .left-location-part .tour-map span {
}
.my-spaces-main .left-location-part .map-box.h-200 {
  height: 200px;
}
.my-spaces-main .left-location-part .map-box iframe {
  border-radius: 3px;
}
.my-spaces-main .left-location-part .address-wrapper {
  padding: 15px 0px;
}
.my-spaces-main .left-location-part .address-wrapper .location-text {
  padding-left: 10px;
}
.my-spaces-main .left-location-part .property-detail-part .property-box {
  min-height: 150px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 50%;
}
.my-spaces-main
  .left-location-part
  .property-detail-part
  .property-box
  .top-part
  img {
  margin-right: 10px;
}
.my-spaces-main
  .left-location-part
  .property-detail-part
  .property-box:last-child {
  margin-right: 0px;
}
.my-spaces-main .right-spaces-part {
  width: calc(100% - 360px);
}
.my-spaces-main .top-boxes-main {
  height: 60%;
  width: 100%;
  padding-bottom: 20px;
}
.my-spaces-main .big-box {
  width: calc(60% - 10px);
  height: 100%;
  margin-right: 20px;
  border: 1px solid transparent;
}
.my-spaces-main .sub-box-wrapper {
  width: calc(40% - 10px);
  height: 100%;
}
.my-spaces-main .bottom-boxes-main {
  width: 100%;
  height: 40%;
  position: relative;
}
.my-spaces-main .top-boxes-main .big-box:hover {
  border: 1px solid var(--color-green-blue-20);
  transition: var(--transiton-3s);
}
.my-spaces-main .sub-box-wrapper .small-box:hover {
  border: 1px solid var(--color-green-blue-20);
  transition: var(--transiton-3s);
}
.my-spaces-main .big-box:hover {
  border: 1px solid var(--color-green-blue-20);
  transition: var(--transiton-3s);
}
/* .my-spaces-main .bottom-boxes-main .comman-white-box.with-bg-img-1:before {
  content: "";
  background-image: url(../images/cell-phone-illuslation.png);
  height: 100%;
  width: 100%;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  right: 40px;
  opacity: 0.6;
}
.my-spaces-main .bottom-boxes-main .with-bg-img-2:before {
  content: "";
  background-image: url(../images/building-illuslation.png);
  height: 100%;
  width: 100%;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  right: 0px;
  z-index: 2;
  opacity: 1;
} */
.my-spaces-main .top-boxes-main .sub-box-wrapper {
}
.my-spaces-main .right-spaces-part .absolute-right-circle {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 2;
}
.my-spaces-main .top-boxes-main .sub-box-wrapper .small-box {
  height: calc(50% - 20px);
  margin: 20px 0px;
}
.my-spaces-main .sub-box-wrapper .small-box {
  border: 1px solid transparent;
}
.my-spaces-main .top-boxes-main .sub-box-wrapper .small-box:first-child {
  margin-top: 0px;
}
.my-spaces-main .top-boxes-main .sub-box-wrapper .small-box:last-child {
  margin-bottom: 0px;
  height: 50%;
}
.my-spaces-main .plan-frame-main {
  padding-top: 15px;
  border-radius: 3px;
}
.plan-main-div .plan-iframe {
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 1;
  right: -10px;
  bottom: -10px;
}
.plan-main {
  margin-top: 15px;
  padding: 10px;
  z-index: 2;
}
.plan-main .top-part .tab-pill-wrapper {
  padding: 0px 20px;
  width: calc(100% - 350px - 30px);
  overflow: auto hidden;
}
.plan-main .bottom-option {
  position: absolute;
  bottom: 80px;
  left: 10px;
}
/*slider*/
.custom-slider {
  position: relative;
}
.custom-slider .slider-main {
  display: flex;
  align-items: center;
  height: 10px;
  position: relative;
  background-color: var(--color-white-05);
  border-radius: 500px;
}
.custom-slider .slider-main .slider-circle {
  position: absolute;
  height: 15px;
  width: 100%;
  border-radius: 500px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}
.custom-slider .slider-main .slider-circle .slider-dots {
  position: relative;
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background-color: #344552;
}
.custom-slider .slider-main .slider-circle .slider-dots.active {
  background: var(--color-gradient);
}
.custom-slider .slider-main .slider-circle .slider-dots.near-active {
  background: var(--color-gradient);
}
.custom-slider .slider-main .slider-circle .slider-dots.near-active:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 500px;
  background: #344552;
  height: 8px;
  width: 8px;
}
.custom-slider .slider-main .slider-bg {
  position: relative;
  background-color: var(--color-white-05);
  width: 100%;
  z-index: 1;
  height: 10px;
  border-radius: 30px;
}
.custom-slider .slider-main .slider-bg .active-sldier-bg {
  background: var(--color-gradient);
  height: 10px;
  border-radius: 30px;
}
/* KPI box */
.kpi-main-wrapper {
  padding: 10px;
}
.kpi-upper-box-text {
  font-size: 9px;
  padding: 2px;
  line-height: 1;
  font-weight: var(--fw-semibold);
  text-align: center;
  text-transform: uppercase;
}
.kpi-second-box {
  padding-left: 10px;
}
.kpi-statatics {
  text-align: left;
  padding: 2px;
  padding-bottom: 0px;
  padding-top: 0;
  /* font-size: 11px; */
  font-size: 18px;
  font-weight: var(--fw-bold);
  font-family: var(--title-family);
  /* color: var(--color-white-60); */
  text-align: center;
  /* line-height: 1;
  padding-top: 5px; */
}
.kpi-tag-box {
  text-align: left;
  font-size: 8px;
  padding-left: 2px;
  opacity: 0.8;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}
.kpi-upper-box{
  /* padding: 3px 0px; */
  display: flex;
  height: 25px;
}
.kpi-main-wrapper .kpi-box {
  /* min-height: 50px; */
  min-height: 60px;
  height: 60px;
  border-radius: 3px;
  padding: 4px;
  /* width: calc(33.33% - 2px); */
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border: 1px solid transparent;
  transition: var(--transiton-3s);
  overflow: hidden;
  flex-grow: 1;
}
.kpi-main-wrapper .kpi-box.w65px{
  width: 65px;
}
.kpi-main-wrapper .kpi-box.w60px{
  width: 60px;
}
.kpi-main-wrapper .kpi-box.h52px{
  height: 52px;
  min-height: 52px
}

.kpi-main-wrapper .kpi-box.w60px {
  width: 60px;
}
.kpi-main-wrapper .kpi-box.h52px {
  height: 52px;
  min-height: 52px;
}
.kpi-main-wrapper .kpi-box:hover{
  background-color: var(--color-white-10);
}
.kpi-main-wrapper a.kpi-box.w_25per:last-child{
  margin-right: 0;
}
.kpi-main-wrapper .kpi-box.h_60px{
  height: 60px;
}
.kpi-main-wrapper .kpi-box.min-height-auto {
  min-height: auto;
  width: calc(33.33% - 7px);
  margin-right: 7px;
}
.kpi-main-wrapper .kpi-box.custom-padding{
  padding-left: 14px;
  padding-right: 11px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.kpi-main-wrapper .kpi-box.min_h40px {
  min-height: 40px;
}
.kpi-main-wrapper .kpi-box.active {
  border: 1px solid var(--color-green-50);
}
.kpi-main-wrapper .kpi-box:last-child {
  margin-right: 0px;
}
/*modal style*/
.custom-modal-style .modal-content {
  background: var(--base-modal-bg);
}
.base-modal-bg{
  background-color: var(--base-modal-bg)!important;
}
.bg-transparent .accordion-button{
  background-color: transparent!important;
}
.form-wrapper-main {
  padding: 20px 0px;
  padding-bottom: 0px;
}
/*left right divide*/
.left-content-part {
  background-color: var(--color-white-03);
  padding: 15px;
  border-radius: 5px;
}
.left-content-part.w-250 {
  width: 250px;
}
.left-content-part.w-300 {
  width: 300px;
}
.left-content-part.w-400 {
  width: 400px;
}
.left-content-part.w-240 {
  width: 240px;
}
.left-content-part.w-280 {
  width: 280px;
}
.left-content-part.w-60 {
  width: 60px;
}
.chat-main .comman-content-scroll li.view-btn {
  top: 64px;
}
.right-content-part {
  flex-grow: 1;
}
.right-contnet-part.width-calc-280px {
  width: calc(100% - 280px - 280px);
}
.right-content-part.width-calc-400  {
  width: calc(100% - 400px);
}
.right-content-part.width-calc-250 {
  width: calc(100% - 250px);
}
.right-content-part.width-calc-300  {
  width: calc(100% - 300px);
}
.right-content-part.width-calc-240.with-mr-15 {
  margin-right: 15px;
}
.right-content-part.width-calc-600 {
  width: calc(100% - 600px);
}
.right-content-part.width-calc-300.with-margin {
  width: calc(100% - 300px - 15px);
  margin-left: 15px;
}
.right-content-part.width-calc-280 {
  width: calc(100% - 280px);
}
.right-content-part .sub-right-part-calc {
  width: calc(100% - 300px);
}
.right-content-part .sub-right-part-calc.width-280 {
  width: calc(100% - 280px);
}
.right-content-part .sub-right-part-calc.with-mr-15 {
  margin-right: 15px;
}
/* notification */
.comman-contnet-wrapper .notification-list-wrapper {
  padding: 10px;
  max-height: calc(100% - 155px);
  overflow: hidden auto;
}
.notification-box {
  position: relative;
}
.notification-box.with-border:before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 52px;
  border-bottom: 1px solid var(--color-border);
  width: calc(100% - 52px);
}
.notification-box:last-child::before {
  display: none;
}
.notification-box .read-unread-status {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.notification-box .read-unread-status .read-notification {
  height: 10px;
  width: 10px;
  border-radius: 500px;
  border: 1px solid var(--color-white-30);
  background: var(--color-white-07);
  display: none;
}
.notification-box .read-unread-status .unread-notification {
  height: 10px;
  width: 10px;
  border-radius: 500px;
  background: var(--color-white-07);
  display: none;
}
.notification-box .read-unread-status.read .read-notification {
  display: block;
}
.notification-box .read-unread-status.unread .unread-notification {
  display: block;
}
.notification-box .left-notification {
  width: calc(100% - 100px);
}
.notification-box .left-notification .notification-detail {
  padding-left: 15px;
}
.notification-box .notification-time {
}
/* filter wrapper */
.filter-wrapper {
  padding: 15px 0px;
  transition: 0.3s all ease-in;
}
.filter-switch .filter-img,
.filter-close-img {
  transition: 0.3s all ease;
  margin: 0;
}
.filter-switch.show-filter .filter-img {
  margin: auto;
  width: 24px;
}
.filter-switch.hide-filter .filter-close-img {
  margin: auto;
  width: 24px;
}
.filter-wrapper .filter-select-box {
  margin-right: 20px;
}
.filter-wrapper .filter-select-box.mr-10:last-child {
  margin-right: 10px;
}
/* Workspace score */
.workspace-score-wrapper {
  height: 100%;
}
.workspace-wrapper {
  padding: 10px;
  height: calc(100% - 110px);
}
.workspace-wrapper .year-list {
  height: calc(100% - 55px);
  overflow: hidden auto;
}
.workspace-wrapper .bottom-workspacescore-box {
  padding-top: 15px;
  position: relative;
}
.workspace-wrapper .bottom-workspacescore-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: var(--color-border);
}
/* owl carousel */
.owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px 0px 0px;
}
.owl-carousel button.owl-dot {
  height: 10px;
  width: 10px;
  background: var(--color-white-20) !important;
  border-radius: 500px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.owl-carousel button.owl-dot.active {
  background: var(--color-green) !important;
}
/* toast  */
.toast-main {
  min-height: auto !important;
}
.toast-main .toast-container {
  position: fixed;
  z-index: 99999;
}
.toast-main .toast-container .toast {
  background: var(--left-panel-bg);
  border: 1px solid var(--color-border-05);
  overflow: hidden;
}
.toast-main .toast-container .toast.toast-with-btn .toast-btn {
  display: block;
}
.toast-main .toast-container .toast .toast-btn {
  display: none;
}
.toast-main .toast-container .toast.sucess {
  border: 1px solid var(--color-green-50);
}
.toast-main .toast-container .toast.unsucess {
  border: 1px solid #dc3545;
  overflow: hidden;
}
.toast-main .toast .toast-header {
  background: var(--base-modal-header-color);
}
.toast-main .toast .toast-header .toast-status-img {
  display: none;
}
.toast-main .toast.sucess .toast-header .toast-status-img.sucess-img {
  display: block;
}
.toast-main .toast.unsucess .toast-header .toast-status-img.unsucess-img {
  display: block;
}
.toast-main .toast-header .btn-close {
  filter: invert(1);
}
.toast-main .toast-header .btn-close:focus {
  outline: 0;
  box-shadow: none;
}
.toast-main .toast .toast-header .faild-img {
}
.toast-main .toast .toast-header .title {
}
.toast-main .toast .toast-header .sub-title {
}
.toast-main .toast .toast-header .close-img {
}
.toast-main .toast .toast-body {
}
/* Comman placeholder */
.comman-placeholder-wrapper {
  min-height: 85px;
  display: flex;
}
.comman-placeholder-wrapper.min-height-auto {
  min-height: auto;
}
.comman-placeholder-wrapper .small-image    {
  height: 35px;
  width: auto;
}
.comman-placeholder-wrapper .big-image
 {
  height: 75px;
  width: auto;
}
.comman-placeholder-wrapper .big-image.h95{
  height: 95px;
}
.comman-placeholder-wrapper span {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  padding-top: 15px;
}
/*prgoress-bar-loader*/
.main-loader-wrapper {
  position: relative;
}
.main-loader-wrapper.full-screen {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: var(--left-panel-bg);
  z-index: 16;
  top: 0;
  left: 0!important;
  user-select: none;
}
.main-loader-wrapper.full-screen.z_20{
  z-index: 20;
}
.main-loader-wrapper.bg_base_color {
  background-color: var(--base-body-color);
}
.offcanvas.offcanvas-end .tast-detail-tab-wrapper {
  position: relative;
}
.offcanvas.offcanvas-end .tast-detail-tab-wrapper .with-height-calc {
  height: calc(100% - 100px);
}
.main-loader-wrapper.full-screen.comman-main-loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0 !important;
  top: 0;
}
.main-loader-wrapper.full-screen.up-left-panel {
  left: 70px;
}
.main-loader-wrapper.full-screen.with-opacity {
  opacity: 0.8;
}
.main-loader-wrapper.full-screen.bg-transparent {
  background: transparent;
}
.main-loader-wrapper .progress {
  background-color: var(--color-white-05);
  height: 10px;
}
.main-loader-wrapper .progress .progress-bar {
  /* background-image: var(--color-green); */
  /* opacity: 0.8; */
}
.progress-bar-striped{
  background-image: linear-gradient(
    135deg,
    var(--color-green) 0%,
    var(--color-green-blue) 100%
  );
  background-size: cover;
  animation: load 4s normal forwards;
}
@keyframes load {
  0% { width: 0; }
  100% { width: 100%; }
}
.main-loader-wrapper .progress .progress-bar.progress-bar-striped {
  /* background-image: linear-gradient(
    45deg,
    rgba(1, 1, 1, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(1, 1, 1, 0.15) 50%,
    rgba(1, 1, 1, 0.15) 75%,
    transparent 75%,
    transparent
  ); */
  
}
/*full screen loader*/
.main-loader-wrapper .loader-video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 16%;
  text-align: center;
}
.main-loader-wrapper .loader-text {
  font-size: 18px;
  font-family: var(--title-family);
  margin-top: -5px;
  /* margin-top: -25px; */
}

.main-loader-wrapper.dropdown-loader .loader-text {
  font-size: 16px;
  margin-top: -15px;
}
.main-loader-wrapper.dropdown-loader .loader-video {
  width: 70%;
}
.main-loader-wrapper.dropdown-loader.width-40 .loader-video {
  width: 40%;
}
.main-loader-wrapper .loader-video video,
.main-loader-wrapper .loader-video img {
  width: 100%;
  height: 100%;
  transform: scale(0.6);
  /* mix-blend-mode: lighten; */
  /* filter: brightness(1.2) contrast(120%); */
  user-select: none;
  z-index: -1;
  margin-top: -30px;
  filter: var(--loader-filter);
}
/* add benchmark form */
.sample-user .user-position {
  position: absolute;
  top: 30%;
  left: 40%;
}
.sample-user .user-position {
  position: absolute;
  top: 30%;
  left: 40%;
}
.sample-user .user-access {
  position: absolute;
  top: 50%;
  right: 30px;
}
/* shorcut box */
.shortcut-wrapper {
  padding: 5px;
  padding-bottom: 0px;
}
.shortcut-wrapper .with-top-border{
  border-top: transparent 1px solid;
}
.light-theme .shortcut-wrapper .with-top-border{
  border-top: var(--color-white-05) 1px solid;
}

.shortcut-box-main {
  width: calc(50% - 10px);
  border: 1px solid var(--color-white-07);
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  position: relative;
}
.shortcut-box-main .shortcut-top .left-img {
  padding-top: 5px;
}
.shortcut-box-main .shortcut-top {
}
.shortcut-box-main img {
}
.shortcut-box-main .shortcut-bottom {
}
.dropdown-menu .shortcut-wrapper .shortcut-box-main:hover {
  background-color: var(--color-white-03);
}
.mension-detail-box {
  display: none;
}
.chat-text:hover .mension-detail-box {
  display: block;
  position: absolute;
  top: 30px;
  left: 0;
  background-color: var(--left-panel-bg);
  width: 150px;
  z-index: 5;
}
.mension-detail-box .mension-details {
  text-align: left;
  width: calc(100% - 30px);
}
/* comman user image and name active */
.comman-user-for-active.active .c-list-detail {
  color: var(--base-font-color);
}
.comman-user-for-active.active .comman-round-box.bg-border-transparet {
  border: 1px solid var(--color-green);
}
.task-sub-list {
  padding: 6px 10px;
  transition: var(--transiton-3s);
  padding-left: 10px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 2px;
}
.childTopicTaskFilter .task-sub-list:first-child{
  margin-top: 0;
}
.task-sub-list:first-child{
 
  margin-top: -3px;

}
.task-sub-list:hover a {
  color: var(--color-green);
}
.task-sub-list:hover .list-icon svg.c-icons[fill]{
  color: var(--color-green);
}
.task-sub-list.active .two-step-schedule-icon .c-icons {
  color: var(--color-green);
}
.task-sub-list.with-position-static {
  position: static !important;
}
.task-sub-list:hover {
  color: var(--base-font-color);
  background-color: var(--color-white-03);
}
.task-sub-list:hover .comman-image-box.with-opacity-zero-hover {
  display: none;
}
.custom-accordian-main
  .accordion-item
  .childTopicTaskFilter.accordian-body
  .right-accordian-header {
  transition: var(--transiton-3s);
  /* margin-right: -70px;
  opacity: 0; */
}
.custom-accordian-main
  .accordion-item
  .childTopicTaskFilter.accordian-body
  .task-sub-list:hover
  .right-accordian-header {
  margin-right: 0px;
  opacity: 1;
}
.custom-accordian-main .accordion-item .task-sub-list .right-accordian-header-pill{
  margin-left: 10px;
  transition: var(--transiton-3s);
}
.custom-accordian-main .accordion-item .task-sub-list:hover .right-accordian-header-pill{
  /* margin-right: 9px; */
}
.custom-accordian-main
  .accordion-item
  .childTopicTaskFilter.accordian-body
  .right-accordian-header.onactive {
  margin-right: 0;
  opacity: 1;
}
.custom-accordian-main
  .accordion-item
  .childTopicTaskFilter.accordian-body
  .right-accordian-header.onactive .right-pill-addtask{
  /* margin-right: 0;
  transition: var(--transiton-3s); */
}
.task-sub-list.active {
  color: var(--color-green);
  /* color: var(--base-font-color); */
    background-color: var(--color-white-03);
}
.task-sub-list.active .left_panel_toggle,
.task-sub-list.active svg.active-svg {
  color: var(--color-green);
  /* stroke: var(--color-green); */
}
.task-sub-list.on-dropdown-toggle .left_panel_toggle,
.task-sub-list.on-dropdown-toggle svg.active-svg {
  color: var(--color-green);
  stroke: var(--color-green);
}
.task-sub-list.active .default-img {
  display: none;
}
.task-sub-list.active .active-img {
  display: block;
}
.task-sub-list.active .hashgreen {
  display: inline-block;
}
.task-sub-list .hashwhite {
  transition: var(--transiton-3s);
}
.task-sub-list .hashgreen {
  display: none;
  transition: var(--transiton-3s);
}
.task-sub-list.active .hashwhite {
  display: none;
}
.task-sub-list .right-accordian-header {
  right: 0px;
  transition: var(--transiton-3s);
  opacity: 1;
  background-color: rgb(35 59 74);
}
.task-sub-list .right-accordian-header.transparent-bg {
  background-color: transparent;
  /* opacity: 0; */
  /* display: none; */
}
.task-sub-list:hover .right-accordian-header.transparent-bg {
  opacity: 1;
  /* display: block; */
}
.task-sub-list .right-accordian-header.transparent-bg .right-pill-addtask {
  transition: var(--transiton-3s);
  /* margin-right: -30px; */
}
.task-sub-list:hover .right-accordian-header.transparent-bg .right-pill-addtask{
  margin-right: 0px;
  transition: var(--transiton-3s);
}
.task-sub-list .two-step-hover-icon {
  margin-right: -40px;
  transition: var(--transiton-1s);
}
.task-sub-list:hover .two-step-hover-icon {
  margin-right: 0px;
}
.dropdown-menu li:last-child .dropdown-divider {
  border-top: 0;
}
.dropdown-menu .dropdown-contain ul li:last-child .hr_sepretr:before {
  display: none;
}
.chat-dropdown.dropdown-menu li:last-child .dropdown-item.hr_1 {
  border-bottom: 0;
}
.max-w-130px {
  width: 130px;
}
.w220px {
  width: 220px;
  flex-grow: 1;
}
.emaildetailviewaccordians .email-body-content {
  background-color: #fff;
}

.doitnow .accordion-button:before {
  content: "";
  background-image: url(/static/media/ic-caret-down-white.dc59fdea.svg) !important;
  z-index: 5 !important;
  position: absolute !important;
  top: 50% !important;
  left: 5px !important;
  height: 15px !important;
  width: 15px !important;
  background-size: 15px !important;
  transform: translateY(-50%) rotate(0deg);
}

.doitnow .accordion-button.collapsed:before {
  transform: translateY(-50%) rotate(-90deg) !important;
}
.grid-row-gap-10{
  grid-row-gap: 10px;
}
footer .footer-links li .absolute_star{
  position: absolute;
  top: 2px;
    right: 4px;
    display: none;
    height: 22px;
    width: 22px;
    border-radius: 500px;
}
footer .footer-links li.fav-view .absolute_star{
  display: flex;
}
.onboarding-footer:before{
  /* position: fixed;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--footer-brop-bg);
  width: 100%;
  height: 80px;
  z-index: -1; */
} 
.onboarding-footer-before-none.onboarding-footer:before{
  display: none;
} 
.lock-cards-wrapper .checklist-listing-wrapper .border-bottom:last-child,
.checklist-listing-wrapper .border-bottom:last-child{
  border-bottom:0 !important;
}
.custom-accordian-main.with-left-header-arrow .hovertextgreen:hover .categorytitle{
  color: var(--color-green);
}
.custom-accordian-main.with-left-header-arrow .accordion-item .accordion-button.collapsed{
  /* margin-bottom: 3px; */
}
.mapoverlaybottom{
  border:1px solid var(--color-green);
}
.mapoverlaybottom:before{
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 80px;
  width: 100%;
  z-index: 2;
  background-image: linear-gradient(transparent,rgba(0,0,0,1)); 
}
.mapsuperficy{
  position: absolute;
  z-index: 3;
  bottom: 15px;
  left: 15px;
}
.multi_inner_wrapper .after-none.comman-image-box::after {
  content: none;
}
.blue-border-w-2{
  border: 2px solid var(--color-blue);
}
.green-border-w-2{
  border: 2px solid var(--color-green);
}
.location-custom-style{
  max-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}
.location-custom-style .location-inner-div{
  top: 44%;
  margin-top: 5px;
}
.mr10px{
  margin-right: 10px;
}

@media screen and (min-width: 1025px) and (max-width: 1600px) {
  .cal-date-box .date-text.c-font.f-22{
    font-size: 18px;
  }
}

.topicsearch{
  border-bottom: 0;
  background-color: var(--color-white-03);
  border-radius: 3px;
  font-size: var(--base-font);
  font-family: var(--base-family);
  color: var(--base-font-color);
  height: auto;
  padding: 5px 10px;
}
.gradientText{
  background: var(--color-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.loader--default {
  display: inline-block;
  position: relative;
  height: 32px;
  width: 32px;
}

.loader--default div {
  position: absolute;
  animation: loader--default 1.2s linear infinite;
  border-radius: 50%;
  height: 5px;
  width: 5px;
  background-color: var(--white);
}

.loader--default div:nth-child(1) {
  animation-delay: 0s;
  top: 29px;
  left: 53px;
}

.loader--default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 18px;
  left: 50px;
}

.loader--default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 9px;
  left: 41px;
}

.loader--default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 6px;
  left: 29px;
}

.loader--default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 9px;
  left: 18px;
}

.loader--default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 18px;
  left: 9px;
}

.loader--default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 29px;
  left: 6px;
}

.loader--default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 41px;
  left: 9px;
}

.loader--default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 50px;
  left: 18px;
}

.loader--default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 53px;
  left: 29px;
}

.loader--default div:nth-child(11) {
  animation-delay: -1s;
  top: 50px;
  left: 41px;
}

.loader--default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 41px;
  left: 50px;
}

@keyframes loader--default {
  0%,
  20%,
  80%,
  100% {
      transform: scale(1);
  }

  50% {
      transform: scale(1.5);
  }
}

.h30wauto{
  height: 30px;
  width: auto;
}
.blackborderbottom{
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.blackbordertop{
  border-top: 1px solid rgba(0,0,0,0.1);
}