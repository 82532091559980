.fixed-left-panel.office-list {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 70px;
  z-index: 23;
  /* z-index: 13; */
  overflow: hidden;
  background-color: var(--base-header-color);
  transition: var(--transiton-3s);
  box-shadow: var(--color-box-shadow);
  transition: all 0.3s linear;;
}
.fixed-left-panel.provider-list {
  transform: translateX(-400px);
  position: fixed;
  top: 0;
  left: 70px;
  height: 100%;
  width: 280px;
  overflow: hidden;
  background-color: var(--base-header-color);
  transition: var(--transiton-3s);
  z-index: 21;
  /* z-index: 11; */
}
.fixed-left-panel.leftpanel-loader{
  width: 350px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 23;
  /* z-index: 13; */
  overflow: hidden;
}
.with-left-penel {
  padding-left: 350px !important;
}
.with-left-penel.no-left-padding,
.with-right-penel.no-left-padding{
  padding-left: 80px !important;
}
.with-left-penel.no-left-padding .after-login-header{
  padding-left: 80px !important;
}
.with-left-penel.no-left-padding footer {
  padding-left: 80px !important;
}
.with-left-penel.no-left-padding .fixed-left-panel.provider-list {
  transform: translateX(-400px);
}
.task-modal-offcanvas-open.with-left-penel.no-left-padding{
  width: calc(100% - (50% - 44px) - 190px);
}
.with-right-penel.no-left-padding #commonCanvasBody {
  width: calc(50% - 155px)!important;
}
.task-modal-offcanvas-open.with-right-penel.no-left-padding {
  width: calc(100% - (50% - 44px) - 190px)!important;
  padding-right: 10px!important;
}
.with-right-penel.no-left-padding.task-modal-offcanvas-open .after-login-header {
  width: calc(100% - (50% - 180px) - 328px)!important;
  padding-right: 10px!important;
}
.with-left-penel .fixed-left-panel.provider-list {
  transform: translateX(0px);
}
.with-left-penel .after-login-header {
  padding-left: 300px !important;
}
.hover-left-penal .after-login-header {
  /* padding-left: 300px !important; */

}
/* .hover-left-penal .header-back-btn, */
.with-left-penel .header-back-btn {
  padding-left: 70px;
}
.with-left-penel footer {
  padding-left: 360px !important;
}
.with-right-penel.task-modal-offcanvas-open footer{
  width: calc(100% - (50% - 180px) - 315px);
  padding-right:0px;
}
.task-modal-offcanvas-open.with-left-penel footer{
  width: calc(100% - (50% - 180px) - 20px);
}
.task-modal-offcanvas-open footer{
  width: calc(100% - (50% - 40px) - 20px);
}
/* .with-right-penel footer{
  width:calc(100% - (var(--space-300) + 10px));
  padding-right:0px;
} */
/* sswith-right-penel */
.with-right-penel {
  padding-right: calc(var(--space-300) + 10px) !important;
}
.with-right-penel.task-modal-offcanvas-open {
  width: calc(100% - (50% - 44px) - 180px);
  padding-right: 0!important;
}
.task-modal-offcanvas-open.with-left-penel {
  width: calc(100% - (50% - 180px) - 26px);
}
.task-modal-offcanvas-open{
  width: calc(100% - (50% - 40px) - 20px);
}
.task-modal-offcanvas-open.with-left-penel .after-login-header {
  width: calc(100% - (50% - 180px) - 30px);
}
.task-modal-offcanvas-open .after-login-header {
  width: calc(100% - (50% - 50px) - 30px);
}
/* .with-right-penel .after-login-header {
  width:calc(100% - (var(--space-300) + 10px));
  padding-right: 10px;
} */
.with-right-penel.task-modal-offcanvas-open .after-login-header {
  width: calc(100% - (50% - 180px) - 315px);
  padding-right: 10px;
}
/* .no-left-padding.with-left-penel.with-right-penel.task-modal-offcanvas-open .after-login-header {
  width: calc(100% - (50% - 180px) - 326px);
  padding-right: 10px;
} */
/* .no-left-padding.with-left-penel.with-right-penel.task-modal-offcanvas-open{
  padding-right: 10px!important;
} */
.with-right-penel .offcanvas.taskDetailModal{
  /* width: calc(50% - 180px);
  right: 305px; */
  width: calc(50% - 175px);
  right: 300px;
  border-right: rgb(21 41 58) 3px solid;
}
.with-right-penel .offcanvas.taskDetailModal.modal-full{
  /* right: calc(var(--space-300) + 10px);
  width: calc(50% - 180px); */
  /* width: calc(50% - 180px); */
  /* width: calc(100% - 307px - 74px);
  right: 307px; */
  /* width: calc(100% - 307px - 173px);
  right: 405px; */
  width: calc(100% - 307px - 163px);
  right: 400px;
  /* border-right: rgb(21 41 58) 3px solid; */
  /* width: calc(100% - 307px - 74px);
  right: 307px; */
}
.with-right-penel .offcanvas.taskDetailModal.modal-full.with-no-left-panel{
  width: calc(100% - 307px - 96px);
}
.with-right-penel .offcanvas.taskDetailModal.modal-full-left-panel{
  /* width: calc(100% - 230px - 77px);
  right: 305px; */
  width: calc(100% - 300px);
  right: 300px;
}
.with-right-penel .offcanvas.taskDetailModal.modal-full.with-comment-section{
  width: calc(100% - 307px - 93px);
}
.with-right-penel .offcanvas.taskDetailModal.modal-full.comment300px{
  width: calc(100% - 305px - 62px);
  right: 300px;
}
.offcanvas.taskDetailModal.modal-full.modal-full-left-panel{
  z-index: 1048;
}
.with-right-penel .task-detail-right-main.task-comment-modal{
  right: 0px;
}
.left-panel-close {
  position: fixed;
  top: 0px;
  left: 400px;
  z-index: 15;
  padding: 10px;
  display: none;
}
/* .fixed-left-panel.office-list {
  box-shadow: var(--color-box-shadow);
} */
.fixed-left-panel.office-list.expanded {
  box-shadow: var(--color-box-shadow);
  width: 350px;
  transition-delay: 1.5s;
}
.searchbutton {
  box-shadow: var(--color-box-shadow);
  width: 350px;
  transition-delay: 0s !important;
}
.fixed-left-panel.provider-list.expanded {
  transform: translateX(0px);
}
/* .fixed-left-panel .fixed-left-panel-wrapper {
  width: 350px;
} */
.fixed-left-panel.office-list .fixed-left-panel-wrapper {
  width: 280px;
  transition: all 0.3s linear;
}
.fixed-left-panel.office-list.expanded .fixed-left-panel-wrapper {
  width: 350px;
}
.fixed-left-panel.provider-list .fixed-left-panel-wrapper {
  width: 280px;
}
.fixed-left-panel.company-link-list .fixed-left-panel-wrapper {
  width: 280px;
}
.fixed-left-panel.company-link-list {
  z-index: 9;
  transform: translateX(-330px);
  position: fixed;
  top: 0;
  left: 70px;
  height: 100%;
  width: 330px;
  overflow: hidden;
  background-color: var(--left-panel-bg);
  transition: var(--transiton-3s);
}
.fixed-left-panel.company-link-list.expanded {
  transform: translateX(0px);
}
.fixed-left-panel .upper-part .company-logo-box {
  padding: var(--space-10);
  background-color: var(--color-white-03);
  height: 60px;
  padding: 12px 10px;
  padding-right: 20px;
}
.fixed-left-panel .upper-part .company-logo-box.with-before:before {
 content: '';
 position: absolute;
 top:0;
 left:0;
 height:100%;
 width:100%;
 background-color: transparent;
 z-index: 5;
 cursor: pointer;
}
.fixed-left-panel .upper-part .left-panel-options {
  height: calc(100% - 80px);
}
.fixed-left-panel .upper-part .left-panel-options .panel-options-scroll {
  max-height: calc(100% - 65px);
  margin-right: -6px;
}
.fixed-left-panel .left-option-box {
  margin: var(--space-10);
}
.fixed-left-panel .left-option-box .comman-round-box.active::after{
  content: "";
  position: absolute;
  height: 100%;
  width: 3px;
  background: var(--color-gradient);
  top: 50%;
  left: -10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 0 500px 500px 0;
}
.fixed-left-panel .office-list-icon.active:after{
  content: "";
  position: absolute;
  height: 100%;
  width: 3px;
  background: var(--color-gradient);
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  border-radius: 0 500px 500px 0;

}
.fixed-left-panel .lower-part {
  padding-top: 10px;
}
.on-active-gradient-slice {
  position: relative;
}
.on-active-gradient-slice.active:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: var(--color-gradient);
  width: 3px;
  height: 100%;
  border-radius: 0px 500px 500px 0px;
}
.on-active-gradient-slice.active.minus10:before {
  left: -10px;
}
.office-list-main .office-box {
  padding:8px 10px;
  position: relative;
  padding-right: 20px;
}
.office-list-main .office-box.inactive-office{
  padding-right: 10px;
}
.office-list-main .office-box.active:hover,
.office-list-main .office-box.add-office-box:hover,
.office-list-main .office-box.inactive-office:hover {
  background-color: transparent;
  transition: var(--transiton-3s);
}
.office-list-main .office-box:hover {
  background-color: var(--color-white-03);
  transition: var(--transiton-3s);
}
.office-list-main .office-box .office-list-desc-right {
  width: 25%;
}
.office-list-main .office-box .office-list-left {
  width: calc(100% - 30%);
}
.office-list-main .office-box .office-list-left .office-subtitle {
  font-size: 9px;
  padding: 4px;
}
.office-list-main .office-box .office-list-left .office-list-circle {
  position: relative;
}
.office-list-main .office-box .office-list-left .office-list-circle:hover .kpi-status-wrapper {
  position: relative;
}
.office-list-main .office-box .office-list-left .office-list-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1px;
}
.fixed-left-panel .office-list-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden auto;
  /* padding-top: 10px; */
  margin-top: 5px;
}
.fixed-left-panel.office-list.expanded .office-list-main{
  overflow:hidden auto ;
}
.fixed-left-panel .left-panel-button {
  margin: 10px;
}
.fixed-left-panel .office-list-main.with-padding {
  padding-left: 5px;
  /* padding-right: 5px; */
  margin-top: 5px;
    padding-top: 0;
}
.office-list-main .office-box.with-badge .badge-custom {
  display: block;
}
.office-list-main .office-box .badge-custom {
  display: none;
}
.with-circle .comman-round-box {
  border: transparent 1px solid;
}
.with-circle.inprogress .comman-round-box {
  border: var(--color-blue) 1px solid;
}
.with-circle.done .comman-round-box {
  border: var(--color-green) 1px solid;
}
.with-circle.not-started .comman-round-box {
  border: var(--color-white-80) 1px solid;
}
.with-circle.pending .comman-round-box {
  border: var(--color-warning) 1px solid;
}
.with-circle.onboarding .comman-round-box{
  border: 1px solid var(--color-white-80);
}
.with-circle.half.inprogress .comman-round-box {
  position: relative;
}
.with-circle.half .comman-round-box:after {
  position: absolute;
  width: calc(50% + 1px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
  content: '';
  background-color: transparent;
  border-radius: 0px 300px 300px 0px;
  border-left: 0px;
  transform: rotate(-180deg);
}
.with-circle.half .comman-round-box.radius_3:after {
  border-radius: 3px;
}
.with-circle.half .comman-round-box:after {
  border: 1px solid #0f2a3cb8;
  border-left: 0;
}
.office-list-main .office-box.active:before {
  content: "";
  position: absolute;
  height: 65%;
  width: 3px;
  background: var(--color-gradient);
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  border-radius: 0px 500px 500px 0px;
}
.office-list-main .office-box:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: var(--color-border) 1px solid;
  right: 0px;
  left: 70px;
  display: none;
}
.office-list-main .office-box.no_border:after {
  display: none;
}
.office-list-main .office-box:last-child:after {
  display: none;
}
.office-list-main .office-box .office-list-detail {
  padding-left: 10px;
  display:none;
}
.fixed-left-panel .office-list.expanded .office-list-main .office-box .office-list-detail {
  display: block;
}
.fixed-left-panel .office-list.expanded .office-list-main .office-box:after {
  display: block;
}

.custom-accordian-main.with-left-header-arrow div:nth-child(5) .accordion-button::after,
.custom-accordian-main.with-left-header-arrow div:nth-child(6) .accordion-button::after {
  /* display: none; */
}

svg.comman-icons.HW20.discord-icon-hover:hover {
  stroke: #00ff85;
}
.footer-mid-btn-animation .footer-mid-circle{
  background-color: var(--color-white-05);
}
/*.left-toggle-none{
	display:none;
}*/
