.onboarding-wrapper {
  padding: 20px;
}
.onboarding-wrapper .left-progress-onboarding {
  width: 400px;
}
.onboarding-wrapper .right-onboarding-part {
  width: calc(100% - 400px);
}
.invite-listing-wrapper {
  padding-top: 15px;
}
.invite-listing-wrapper .invite-list-box {}
.invite-listing-wrapper .invite-list-box .list_left {
  width: calc(100% - 400px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.invite-listing-wrapper .invite-list-box .link-action {}

.onboarding-wrapper-main {
  /* width: 80%; */
  /* width: 50%; */
  width: 980px;
  position: relative;
  margin-top: 20px!important;
  padding: 20px 0px 20px 0px;
  height: calc(100% - 20px)!important;
}
.onboarding-with-bg {
  /* margin: auto; */
  /* height: calc(100% - 20px)!important; */
  /* padding: 20px 20px 20px 20px; */
  background: var(--color-white-03-solid);
  /* margin-top: 20px!important; */
  /* width: 980px; */
}
.checklist-creation-abs, .onboarding-wrapper-main.checklist-creation-abs {
  position: fixed;
  height: 100%!important;
  width: 100%;
  top: 0;
  left: 0;
  padding: 20px;
  background: var(--base-body-color);
  z-index: 25;
  margin-top: 0!important;
}
.onboarding-header{
  /* padding: auto 15px; */
  padding: 0 20px 0 20px;
}
.onboarding-header .sub-header{
  color: var(--color-white-60);
}
.onboarding-wrapper-main.with-modified-width {
  /* width: 80%; */
  width: 1280px;
}
.onboarding-footer{
  position: absolute;
  width: 100%;
  bottom:0;
  left: 0;
}
.onboarding-footer .onboarding-footer-tab{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.onboarding-footer .onboarding-footer-tab .footer-tab{
  background-color: #3a4e5a;
  /* width: 15px; */
  width: 55px;
  height: 5px;
  border-radius: 60px;
  margin: 0 3px;
}
.onboarding-footer .onboarding-footer-tab .footer-tab.active{
  background: var(--btn-primary-bg);
}
.onboarding-content-scroll-wrapper.comman-main-box,
.onboarding-content-scroll-wrapper{
  /* padding: 0 15px; */
}
.onboarding-content-scroll-wrapper{
  flex-grow: 1;
  /* height: calc(100% - 225px); */
  /* height: calc(100% - 125px); */
  overflow: hidden auto;
  padding: 0 20px 0 20px;
}
.space-select-box.select-input-cox {
  /* height: 240px; */
  width: 230px;
}
.space-select-box.select-input-cox.for-disabled {
  opacity: 0.7;
}
.h_230px{
  height: 230px !important;
}
.select-input-cox {
  width: 100%;
  position: relative;
  background-color: var(--color-white-03);
  border-radius: 5px;
}
.select-input-cox.h53px{
  height: 53px;
}
a.select-input-cox:hover{
  background-color: var(--color-white-07);
}
.select-input-cox.active {
  border: var(--color-green-50) 1px solid;
  background-color: var(--color-white-05);
}
.select-input-cox .check-image {
  display: none;
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: var(--color-white-05);
  border-radius: 50%;
}
.select-input-cox .check-image .check-image-box{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: auto;
  background: var(--btn-primary-bg);
  display: flex;
  position: relative;
}
.check-image-box .color-white-03-solid.c-icons path, .check-image-box .color-white-03-solid.c-icons line, .check-image-box .color-white-03-solid.c-icons circle, .check-image-box .color-white-03-solid.c-icons polyline, .check-image-box .color-white-03-solid.c-icons rect {
  stroke-width: 36px;
}
.select-input-cox .check-image svg{
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-input-cox .check-image .check-image-box svg{
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70%;
  width: 70%;
  transform: translate(-50%, -50%);
}
.select-input-cox.active  .check-image {
  display: block;
}
.select-input-cox.active .main-image svg.c-icons[fill]{
  color: var(--color-green);
}
.select-input-cox .form-check-input{
  /* background-color: var(--color-white-03); */
  background-color: transparent;
}
.select-input-cox .form-check-input[type=radio] {
  border-radius: 5px;
}
.select-input-cox .form-check-input:checked {
  background-size: 0px;
  border: var(--color-green-50) 1px solid;
  /* border: 1px solid transparent; */
  /* border-image: linear-gradient(to right, var(--color-green), var(--color-green-blue)); */
  /* border-image-slice: 1; */
  /* background-color: transparent; */
  /* background: linear-gradient(var(--color-white-03-solid), var(--color-white-03-solid)) padding-box,    linear-gradient(45deg, var(--color-green), var(--color-green-blue))border-box; */
}
.select-input-cox.checked-input-bg-white-03 .form-check-input:checked {
  background-size: 0px;
  border: transparent 1px solid;
  background-color: var(--color-white-03)!important;
}
.select-input-cox.with-input-style .form-check-input:checked {
  background-image: url(../images/ic-check-green.svg);
  background-size: 18px;
  border: transparent 1px solid;
  /* background-color: transparent; */
}
.select-input-cox .form-check-input ~ .check-image{
  display: none;
}
.select-input-cox .form-check-input:checked ~ .check-image{
  display: inline-flex;
  position: absolute;
  top: 8px;
  right: 8px;
} 
.select-input-cox .form-check-input:checked ~ .active svg.c-icons[fill]{
  color: var(--color-green)!important;
}
.select-input-cox .form-check-input:checked ~ .check-image
.onboarding-map{
  position: relative;
  width: 100%;
  height: 100%;
}
.onboarding-map-input{
  /* background-color: #112C3E!important; */
  background-color: var(--bg-white-hex-03)!important;
  /* padding: 15px; */
  border-radius: 3px;
}
.custom-select-menu.onboarding-map-input{
  background-color: transparent !important;
  padding: 0;
}
.custom-select-menu.onboarding-map-input .react-select__control{
  background-color: #112C3E!important;
  height: 50px;
}
.custom-select-menu.h_45 .react-select__control{

  height: 53px;
}
.custom-select-menu.h_30px .react-select__control {
  height: 30px;
}
.custom-select-menu.h_30px .react-select__value-container--is-multi{
  padding-top: 0;
  padding-bottom: 0;
}
.inputh30{
  height: 38px;
}
.custom-select-menu.h_30px .react-select__value-container--is-multi{
  margin-left: 0;
  margin-right: 0;
}
.custom-select-menu.onboarding-map-input .react-select__control{
  background-color: var(--color-white-03)!important;
  min-height: 50px;
  border-color: transparent;
  padding: 0 5px;
}

/* Input type range custom style */
.rs-slider{
	position: relative;
}
.rs-slider-bar{
	background: var(--color-white-05);
    border-radius: 40px;
    cursor: pointer;
    height: 5px;
}
.rs-slider-progress-bar{
    background: var(--color-gradient);;
    height: 100%;
	border-radius: 30px;
}

.rs-slider-handle{
	background: var(--color-gradient);
	border: 2px solid var(--left-panel-bg);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    top: -7px;;
	position: absolute;
	margin-left: -5px;
	cursor: pointer;
}
.rs-slider-tooltip{
	opacity: 0;
}
/* Input type range custom style new */
.css-187mznn-MuiSlider-root,
.MuiSlider-root {
  border-radius: 40px!important;
  height: 5px!important;
}
.css-14pt78w-MuiSlider-rail,
.MuiSlider-rail {
  background: var(--color-white-05)!important;
}
.css-1gv0vcd-MuiSlider-track,
.MuiSlider-track {
  background: var(--color-gradient)!important;
  border-color: transparent!important;
}
.css-eg0mwd-MuiSlider-thumb,
.MuiSlider-thumb {
  border: 2px solid var(--left-panel-bg)!important;
  background: var(--color-gradient)!important;
  width: 18px!important;
  height: 18px!important;
}
.css-eg0mwd-MuiSlider-thumb:hover, .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
  box-shadow: none!important;
  /* border: 1px solid var(--left-panel-bg)!important; */
  width: 20px!important;
  height: 20px!important;
}
/* Input type range custom style End */
.onboarding-space-listing .space-right .space-right-action-icon{
  margin-right: -20px;
  opacity: 0;
  transition: var(--transiton-3s);
}
.onboarding-space-listing:hover .space-right .space-right-action-icon{
  margin-right: 0;
  opacity: 1;
  transition: var(--transiton-3s);
}
.create-of-floor-main{
  display: flex;
  flex-direction: column;
  height: calc(100% - 130px);
}
.onboarding-3d-viewer-main{
  min-height: 32vh;
  height: 100%;
  width: 100%;
}
.onboarding-3d-viewer-main.fullscreen-3d-viewer{
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9;
  /* width: calc(100% - 72px); */
  /* height: calc(100% - 50px); */
  width:100%;
  height: 100%;
}
.onboarding-3d-viewer{
  width: 100%;
  height: 100%;
  background-color: var(--calendar-modal-color);
}
.css-187mznn-MuiSlider-root,
.MuiSlider-root {
  border-radius: 40px!important;
  height: 5px!important;
}
.css-14pt78w-MuiSlider-rail,
.MuiSlider-rail {
  background: var(--color-white-05)!important;
}
.css-1gv0vcd-MuiSlider-track,
.MuiSlider-track {
  background: var(--color-gradient)!important;
  border-color: transparent!important;
}
.css-eg0mwd-MuiSlider-thumb,
.MuiSlider-thumb {
  border: 2px solid var(--left-panel-bg)!important;
  background: var(--color-gradient)!important;
  width: 18px!important;
  height: 18px!important;
}
.css-eg0mwd-MuiSlider-thumb:hover, .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
  box-shadow: none!important;
  /* order: 1px solid var(--left-panel-bg)!important;  */
  width: 20px!important;
  height: 20px!important;
}
.onboarding-map >div:last-child{
  position: initial!important;
}
.dealing-list .dealing-list-item.active{
  background-color: var(--bg-white-03-solid);
}
.active.comman-list:after{
  content: none;
}
.tab-active.active:before{
  content: "";
  position: absolute;
  height: 65%;
  width: 3px;
  background: var(--color-gradient);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-radius: 0 500px 500px 0;
}
.comman-list .steps-green-line{
  position: relative;
  padding: 15px;
}
.comman-list .steps-green-line:after{
  position: absolute;
  content: '';
  width: 2px;
  height: 93%;
  background: var(--color-green-50);
  top: 18px;
  left: -11px;

}
.comman-list .steps-green-line:last-child:after{
  content: none;
}
.comman-list .steps-green-line .absolute-content{
  position: absolute;
  left: -25px;
  z-index: 1;
  background: var(--calendar-modal-color);
  border-radius: 50%;
  padding: 2px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.css-eg0mwd-MuiSlider-thumb.Mui-active{
  box-shadow: 0px 0px 0px 14px var(--color-white-05)!important;
}
.comman-input-check-box:hover {
  background-color: var(--color-white-05);
}
.for-disabled.comman-input-check-box:hover,
.space-select-box:hover .for-disabled.comman-input-check-box,
.for-disabled:hover .comman-input-check-box{
  background-color: initial;
}
.on-hover-bg-white-05:hover {
  background-color: var(--color-white-05);
}
.hide-magnify.onboarding-map .gm-control-active.gm-fullscreen-control{
  display: none;
}
.onboarding-wrapper-main table .form-check-input {
  height: 32px;
  width: 32px;
  background-size: 24px;
}
.onboarding-wrapper-main .form-check-input.h20w20{
  height: 20px;
  width: 20px;
  background-size: 16px;
}
.onboarding-wrapper-main .form-switch .form-check-input {
  width: 35px;
  height: 18px;
}
.onboarding-wrapper-main table.dataTable tbody td {
  vertical-align: middle;
}
/* .onboarding-wrapper-main .dataTable.smaller-table tbody td */
.onboarding-wrapper-main .comman-table.dataTable.smaller-table thead th, .onboarding-wrapper-main .comman-table.dataTable.smaller-table thead td, .onboarding-wrapper-main .comman-table.dataTable.smaller-table tbody th{
  padding: 16px 6px;
}
.on-hover-hide-show-main{
  position: relative;
}
.on-hover-hide-show-main .on-hover-hide{
  opacity: 1;
  z-index: 1;
  transition: opacity 0.3s ease-out;
}
.on-hover-hide-show-main:hover .on-hover-hide{
  z-index: -1;
  transition: opacity 0.3s ease-out;
  opacity: 0;
}
.on-hover-hide-show-main .on-hover-show{
  z-index: -1;
  transition: opacity 0.3s ease-out;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.on-hover-hide-show-main:hover .on-hover-show{
  opacity: 1;
  z-index: 1;
  transition: opacity 0.3s ease-out;
}
.checklist-creation-abs .custom-select-menu.custom-select-sm.with-filter-select.min-h-33 {
  height: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  border-radius: 3px;
}
.top-110px{
  top: 110px;
}
thead.table-th-px-26px th {
  padding: 12px 26px!important;
}